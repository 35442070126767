import { ComponentType } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { AuthState } from '@okta/okta-auth-js'

export interface RoleContext {
  authState: AuthState | null
  hasRole: (s: string) => boolean
  hasAnyRole: (...s: string[]) => boolean
  hasJobAccess: () => boolean
}

export const useRoleContext = (): RoleContext => {
  const { authState } = useOktaAuth()

  const hasRole = (role: string): boolean => {
    return !!authState?.accessToken?.claims['groups']?.toString()?.includes(role)
  }

  const hasAnyRole = (...roles: string[]): boolean => {
    for (const role of roles) {
      if (hasRole(role)) {
        return true
      }
    }
    return false
  }

  const hasJobAccess = (): boolean => {
    return hasRole('App.emea.shipmentReport.admin.job')
  }

  return {
    hasRole,
    hasAnyRole,
    hasJobAccess,
    authState,
  }
}

export const withRoleContext = <P extends RoleContext>(Component: ComponentType<P>) => {
  return function WithRoleContextHoc(hocProps: Omit<P, keyof RoleContext>) {
    const role = useRoleContext()
    return <Component {...role} {...(hocProps as P)} />
  }
}

export default useRoleContext
