import React, { useEffect, useState } from 'react'
import { Label, Select, TextField as EdsTextField } from '@nike/eds'
import { useController, UseControllerProps, useFormContext } from 'react-hook-form'
import './FrequencyField.css'
import {
  dayOptions,
  extractCronFields,
  frequencyOptions,
  hourOptions,
  makeCronFromFields,
} from '../../utils/datautils/cron'
import { getTimeInUTC } from '../../utils/datautils/utils'

export interface FrequencyFieldProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof EdsTextField>,
      'id' | 'name' | 'value' | 'defaultValue' | 'onBlur'
    >,
    Omit<UseControllerProps, 'control'> {
  control: any
}

export const FrequencyField = ({
  name,
  control,
  type = 'text',
  label,
  rules = undefined,
  ...rest
}: FrequencyFieldProps) => {
  const { register, setValue, getValues } = useFormContext()
  const frequencyValues = extractCronFields(getValues(name))
  const {
    field: { onChange: onChangeSelect, value: valueSelect, ...fieldPropsSelect },
  } = useController({
    name: `${name}Select`,
    control,
    defaultValue: frequencyOptions.find(it => it.value === frequencyValues.frequencyOption),
  })

  const {
    field: { onChange: onChangeSelect1d, value: valueSelect1d, ...fieldPropsSelect1d },
  } = useController({
    name: `${name}Select1d`,
    control,
    defaultValue: hourOptions.find(it => it.value === frequencyValues.firstHour),
  })

  const {
    field: { onChange: onChangeSelect2d, value: valueSelect2d, ...fieldPropsSelect2d },
  } = useController({
    name: `${name}Select2d`,
    control,
    defaultValue: hourOptions.find(it => it.value === frequencyValues.secondHour),
  })

  const {
    field: { onChange: onChangeSelect1w, value: valueSelect1w, ...fieldPropsSelect1w },
  } = useController({
    name: `${name}Select1w`,
    control,
    defaultValue: dayOptions.find(it => it.value === frequencyValues.dayOfWeek) ?? dayOptions[1],
  })

  register(name, rules)

  const [currentTimeInUTC, setCurrentTimeInUTC] = useState(getTimeInUTC())

  useEffect(() => {
    setInterval(() => {
      setCurrentTimeInUTC(getTimeInUTC)
    }, 1000)
  }, [])

  useEffect(() => {
    const cronString = makeCronFromFields({
      firstHour: valueSelect1d.value,
      secondHour: valueSelect2d.value,
      dayOfWeek: valueSelect1w.value,
      frequencyOption: valueSelect.value,
    })

    setValue(name, cronString)
  }, [valueSelect, valueSelect1d, valueSelect2d, valueSelect1w, setValue, name])

  return (
    <div className="flex-column flex-wrap content-start justify-items-start">
      <div className="flex-column" style={{ gap: 'var(--eds-space-8)' }}>
        <Select
          id={`${name}Select`}
          {...fieldPropsSelect}
          onKeyDown={e => e.preventDefault()}
          options={frequencyOptions}
          value={valueSelect}
          onChange={e => onChangeSelect(e)}
          className="eds-select frequencySelect"
          label={label}
        />

        <div className="flex-row" style={{ gap: 'var(--eds-space-16)' }}>
          <Select
            id={`${name}Select1d`}
            {...fieldPropsSelect1d}
            onKeyDown={e => e.preventDefault()}
            options={hourOptions}
            value={valueSelect1d}
            onChange={e => onChangeSelect1d(e)}
            className="eds-select frequencySelect"
            label=""
          />

          {/* Only show if twice a day */}
          {valueSelect.value === '2d' && (
            <Select
              id={`${name}Select2d`}
              {...fieldPropsSelect2d}
              onKeyDown={e => e.preventDefault()}
              options={hourOptions}
              value={valueSelect2d}
              onChange={e => onChangeSelect2d(e)}
              className="eds-select frequencySelect"
              label=""
            />
          )}

          {/* Only show if once a week */}
          {valueSelect.value === '1w' && (
            <Select
              id={`${name}Select1w`}
              {...fieldPropsSelect1w}
              onKeyDown={e => e.preventDefault()}
              options={dayOptions}
              value={valueSelect1w}
              onChange={e => onChangeSelect1w(e)}
              className="eds-select frequencySelect"
              label=""
            />
          )}
        </div>

        <Label font="legal-1" className="eds-color--text-secondary">
          Hours are in UTC (current time in UTC: {currentTimeInUTC})
        </Label>
      </div>
    </div>
  )
}
