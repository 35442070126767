import Config from '../config/Config'
import axios from 'axios'
import { setupAxiosOktaInterceptors } from '../config/AxiosInterceptors'

export const carrierConfigClient = setupAxiosOktaInterceptors(
  axios.create({
    baseURL: `${Config.CARRIER_CONFIG_URL}/${Config.BASEPATH_CARRIER_CONFIG}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
)

export interface XDocScacCode {
  xDockScacCode: string
  active?: boolean
}

export interface CarrierHubResponse {
  carrierCode: string
  description?: string
  carrierName?: string
  carrierAccountCode: string
  hubCode?: string
  xDockScacCodes?: XDocScacCode[]
}

export const fetchCarriers = async (): Promise<CarrierHubResponse[]> => {
  return carrierConfigClient.get(`/authorized/carriers/v1`).then(response => response.data)
}
