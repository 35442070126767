import { useNavigate, useLocation } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { getOktaAuth } from './config/OktaConfig'
import { Layout } from './pages/layout'
import './common/styles/App.css'
import { useEffect } from 'react'
import { logUsage } from './services/ShipmentService'

const App: React.FC = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) =>
    navigate(toRelativeUrl(originalUri || '/', window.location.origin))

  useEffect(() => {
    const page = location.pathname.startsWith('/')
      ? location.pathname.split('/')[1]
      : location.pathname
    logUsage(page ? page : 'view', 'load')
  }, [location])

  return (
    <Security oktaAuth={getOktaAuth()} restoreOriginalUri={restoreOriginalUri}>
      <Layout></Layout>
    </Security>
  )
}

export default App
