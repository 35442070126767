import { format, parseISO } from 'date-fns'
import { checkRelativeDate } from './utils'
import { formatInTimeZone } from 'date-fns-tz'

export interface Formatter {
  (data: any, options?: object): any
}

export function date(date, showTime = false, cet = false) {
  if (date && date !== 'false') {
    let toFormat = date
    if (typeof date === 'string') {
      toFormat = parseISO(date)
    }
    const formatTemplate = showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'
    if (cet) {
      return formatInTimeZone(toFormat, 'Europe/Brussels', formatTemplate)
    }
    return format(toFormat, formatTemplate)
  }
  return ''
}

export function formatDatePicker(date) {
  if (checkRelativeDate(date)) {
    return date
  }
  if (date) {
    let toFormat = date
    if (typeof date === 'string') {
      toFormat = parseISO(date)
    }

    return format(toFormat, 'yyyy-MM-dd')
  }
  return ''
}

export function bool(value: any) {
  return value ? '☒' : '☐'
}

export function list(value, { separator = ', ', format = defaultFormatter } = {}) {
  return value
    ? value
        .flat(Infinity)
        .map(it => format(it))
        .join(separator)
    : ''
}

export function defaultFormatter(value) {
  if (value && typeof value === 'object') {
    return value.toString()
  }
  if (typeof value === 'boolean') {
    return bool(value)
  }

  if (Array.isArray(value)) {
    return list(value)
  }
  return value === null || value === undefined ? '' : value
}

export function shipmentVendor(vendor) {
  if (Array.isArray(vendor)) {
    return list(vendor, { format: shipmentVendor })
  }
  return `${vendor?.vendorCode} / ${vendor?.hubCode}`
}

export function shipTo(shipTo) {
  return `${shipTo?.shipToCode} / ${shipTo?.shipToName}`
}
