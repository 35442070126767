import {
  Autocomplete as MuiAutoComplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material'
import { useController, UseControllerProps } from 'react-hook-form'
import { default as TextFieldMui } from '@mui/material/TextField'

export interface AutoCompleteProps
  extends Omit<
      MuiAutocompleteProps<any, any, any, any, any>,
      'id' | 'name' | 'value' | 'defaultValue' | 'onChange' | 'onBlur' | 'renderInput'
    >,
    Omit<UseControllerProps, 'control'> {
  control: any
}

export const Autocomplete = ({ name, control, rules = undefined, options, ...rest }) => {
  const {
    field: { onChange, ...fieldProps },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  })
  return (
    <MuiAutoComplete
      id={name}
      {...fieldProps}
      options={options}
      disablePortal
      onChange={(_, data) => onChange(data)}
      renderInput={params => (
        <TextFieldMui
          {...params}
          helperText={error?.message}
          error={!!error}
          style={{ minWidth: '100%' }}
        />
      )}
      {...rest}
    />
  )
}
