import { useEffect, useMemo, useState } from 'react'
import { useQueryState } from '../../common/hooks/useQueryState'
import { TableLayout, useServerDataTable } from '../../common/components/table'
import { useOktaAuth, withOktaAuth } from '@okta/okta-react'
import { logUsage } from '../../services/ShipmentService'
import { Button, Icon, Text } from '@nike/eds'
import { removeEmptyAndDuplicateFields } from '../../common/utils/datautils/sanitize'
import { Job } from '../../services/JobService'
import { useJobList } from '../../common/hooks/useJobQueries'
import { JobModal } from './jobmodal/JobModal'
import { DeleteJobModal } from './deletejobmodal/DeleteJobModal'
import useRoleContext from '../../common/hooks/useRoleContext'
import { Navigate } from 'react-router-dom'
import { jobColumns } from './JobColumns'

export const reportVersions = [
  { value: 'latest', label: 'Normal' },
  { value: 'odt', label: 'ODT' },
]

const useJobTable = (queryState, toggleSortBy, isFetching, isSuccess, isError, data) => {
  const columns = useMemo(() => jobColumns, [])

  return useServerDataTable({
    queryState,
    overrides: {
      toggleSortBy,
    },
    columns,
    isFetching,
    isSuccess,
    isError,
    data,
  })
}

const pageSizeOptions = [10, 25, 50, 100, 200, 500]

const Jobs = () => {
  const { state: queryState, toggleSortBy } = useQueryState<Job>()
  const { authState } = useOktaAuth()
  const { isFetching, isError, isSuccess, data, refetch } = useJobList(authState)
  const [jobModalOpen, setJobModalOpen] = useState<boolean>(false)
  const [deleteJobModalOpen, setDeleteJobModalOpen] = useState<boolean>(false)
  // Table
  const tableInstance = useJobTable(queryState, toggleSortBy, isFetching, isSuccess, isError, data)
  const noSelectedItems = Object.keys(tableInstance.state.selectedRowIds).length === 0

  const currentJobNames = isSuccess && data ? data.data.map(job => job.jobName) : []

  useEffect(() => {
    if (queryState.filter) {
      logUsage('jobs', 'search', { filters: removeEmptyAndDuplicateFields(queryState.filter) })
    }
  }, [queryState.filter])

  const handleCloseJobModal = () => {
    setJobModalOpen(false)
  }

  const handleCloseDeleteJobModal = () => {
    setDeleteJobModalOpen(false)
  }

  const hasJobAccess = useRoleContext().hasJobAccess()

  if (!hasJobAccess) {
    return <Navigate to="/" replace />
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--eds-space-8)',
          marginBottom: 'var(--eds-space-72)',
        }}
      >
        <div
          className="flex-row flex-nowrap content-space-between justify-items-center"
          style={{ padding: 'var(--eds-space-16) var(--eds-space-16) 0 var(--eds-space-16)' }}
        >
          <Text font="title-4" as="h4">
            Automated Shipment Report Jobs
          </Text>
        </div>
        <div
          className="flex-row flex-nowrap content-space-between justify-items-center"
          style={{
            padding: 'var(--eds-space-16) var(--eds-space-16) 0 var(--eds-space-16)',
            marginBottom: 'var(--eds-space-16)',
          }}
        >
          <div>
            <Button
              size="medium"
              style={{ marginRight: 'var(--eds-space-8)' }}
              disabled={noSelectedItems}
              beforeSlot={<Icon name="Edit" />}
              onClick={() => setJobModalOpen(true)}
            >
              Edit Job
            </Button>

            <Button
              size="medium"
              style={{ marginRight: 'var(--eds-space-8)' }}
              disabled={noSelectedItems}
              beforeSlot={<Icon name="Delete" />}
              onClick={() => setDeleteJobModalOpen(true)}
            >
              Delete Job
            </Button>
          </div>

          <Button
            variant="primary"
            className="Button"
            disabled={!noSelectedItems}
            beforeSlot={<Icon name="Plus" />}
            onClick={() => setJobModalOpen(true)}
          >
            New Job
          </Button>
        </div>

        <TableLayout
          {...{ ...tableInstance, pageSizeOptions, isFetching, isError }}
          queryState={queryState}
          refetchData={refetch}
          hasPagination={false}
        />
      </div>
      {jobModalOpen && (
        <JobModal
          jobModalOpen={jobModalOpen}
          onCloseJobModal={handleCloseJobModal}
          selectedJob={tableInstance.selectedFlatRows[0]?.original}
          currentJobNames={currentJobNames}
          refetchData={refetch}
        />
      )}
      <DeleteJobModal
        deleteJobModalOpen={deleteJobModalOpen}
        selectedRow={tableInstance.selectedFlatRows[0]}
        onCloseDeleteJobModal={handleCloseDeleteJobModal}
        refetchData={refetch}
      />
    </>
  )
}

export default withOktaAuth(Jobs)
