import React, { useEffect, useMemo, useState } from 'react'
import { useQueryState } from '../../common/hooks/useQueryState'
import { useShipmentList } from '../../common/hooks/useShipmentQueries'
import { FilterForm } from './filterform/FilterForm'
import { TableLayout, useServerDataTable } from '../../common/components/table'
import { useOktaAuth, withOktaAuth } from '@okta/okta-react'
import Chips, { ChipValue } from '../../common/components/chips/Chips'
import { CarrierHub, logUsage, RebookingDeliveryViewFilter } from '../../services/ShipmentService'
import { Button, Icon, Spinner } from '@nike/eds'
import { date } from '../../common/utils/datautils/formatter'
import { DownloadModal } from './downloadmodal/DownloadModal'
import { Divider } from '@mui/material'
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import ButtonUnstyled from '@mui/base/ButtonUnstyled'
import { removeEmptyAndDuplicateFields } from '../../common/utils/datautils/sanitize'
import { useFilters } from '../../common/hooks/useFilters'
import { checkRelativeDate } from '../../common/utils/datautils/utils'
import { shipmentReportColumns } from './ShipmentReportColumns'

const useShipmentReportTable = (
  queryState,
  gotoPage,
  setPageSize,
  toggleSortBy,
  isFetching,
  isSuccess,
  isError,
  data
) => {
  const columns = useMemo(() => shipmentReportColumns, [])

  return useServerDataTable({
    queryState,
    overrides: {
      gotoPage,
      setPageSize,
      toggleSortBy,
    },
    columns,
    isFetching,
    isSuccess,
    isError,
    data,
  })
}

const pageSizeOptions = [10, 25, 50, 100, 200, 500]
function convertRebookedCheckbox(field: string, value: string) {
  if (field === 'rebookedCheckbox' && value) {
    return 'Rebooking Type: Rebooked'
  }
  if (field === 'holdCheckbox' && value) {
    return 'Rebooking Type: Hold'
  }
}

function convertIDPCheckbox(field: string, value: string) {
  if (field === 'idpCheckbox' && value) {
    return 'IDPFilter: IDP'
  }
  if (field === 'nonIdpCheckbox' && value) {
    return 'IDPFilter: Non-IDP'
  }
}

const makeChipsArray = (filter: RebookingDeliveryViewFilter = {}) => {
  const chipsArrayFormat = (field: string, value: any) => {
    switch (field) {
      case 'carrierHubs':
        const chipValue = value as CarrierHub
        return chipValue?.carrierAccountCode !== undefined && chipValue?.hubCode !== undefined
          ? `${chipValue?.carrierAccountCode}-${chipValue?.hubCode}`
          : undefined
      case 'shipToCodes':
        return `Ship to Number: ${value}`
      case 'shipmentReferences':
        return `Packlist: ${value}`
      case 'iDPPlannedFromTimestamp':
        return value
          ? checkRelativeDate(value)
            ? `EDD from: ${value}`
            : `EDD from: ${date(value)}`
          : undefined
      case 'iDPPlannedToTimestamp':
        return value
          ? checkRelativeDate(value)
            ? `EDD to: ${value}`
            : `EDD to: ${date(value)}`
          : undefined
      case 'countries':
        return `Country: ${value}`
      case 'rebookedCheckbox':
        return convertRebookedCheckbox(field, value)
      case 'holdCheckbox':
        return convertRebookedCheckbox(field, value)
      case 'idpCheckbox':
        return convertIDPCheckbox(field, value)
      case 'nonIdpCheckbox':
        return convertIDPCheckbox(field, value)
      case 'plannedGoodsIssueFromTimestamp':
        return value
          ? checkRelativeDate(value)
            ? `PGI from: ${value}`
            : `PGI from: ${date(value)}`
          : undefined
      case 'plannedGoodsIssueToTimestamp':
        return value
          ? checkRelativeDate(value)
            ? `PGI to: ${value}`
            : `PGI to: ${date(value)}`
          : undefined
      default:
        return value
    }
  }
  return Object.keys(filter)
    .flatMap(field => {
      const filterValue = filter[field]
      if (Array.isArray(filterValue)) {
        return filterValue.map((value, index) => ({
          field,
          value,
          index,
          stringValue: chipsArrayFormat(field, value),
        }))
      }
      let stringValue = chipsArrayFormat(field, filterValue)
      return stringValue !== undefined
        ? [{ field, value: filterValue, stringValue: stringValue }]
        : (undefined as unknown as ChipValue[])
    })
    .filter(it => it !== undefined && it.value !== undefined)
}

const ShipmentReport = () => {
  const {
    state: queryState,
    setFilter,
    gotoPage,
    setPageSize,
    toggleSortBy,
    clearFilter,
  } = useQueryState<RebookingDeliveryViewFilter>()
  const { authState } = useOktaAuth()
  const { isFetching, isError, isSuccess, data, refetch } = useShipmentList(queryState)
  const [expandFilterForm, setExpandFilterForm] = useState(false)
  const [exportModalOpen, setExportModalOpen] = useState<boolean>(false)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [popperOpen, setPopperOpen] = useState<boolean>(false)
  const [odtVersionDownload, setOdtVersionDownload] = useState<boolean>(false)
  const { data: filters, isSuccess: isFiltersFetched } = useFilters(authState, 'shipmentReport')
  const anchorRef = React.useRef<HTMLDivElement>(null)
  /* Start chips */
  const chipsArray = makeChipsArray(queryState.filter)

  useEffect(() => {
    if (isFiltersFetched) {
      if (filters?.filters?.filter(filter => filter.default)) {
        setFilter(filters.filters.filter(filter => filter.default)[0].filter)
      } else {
        return undefined
      }
    }
    // eslint-disable-next-line
  }, [isFiltersFetched])

  useEffect(() => {
    if (queryState.filter) {
      logUsage('view', 'search', { filters: removeEmptyAndDuplicateFields(queryState.filter) })
    }
  }, [queryState.filter])

  const handleChipDelete = (value: ChipValue) => {
    const currentValue = queryState.filter?.[value.field]
    if (currentValue === undefined || queryState.filter === undefined) {
      return
    }
    const currentFilter = queryState.filter
    let newValue
    if (Array.isArray(currentValue)) {
      const values = currentValue.filter((v, i) => i !== value.index)
      newValue = values?.length !== 0 ? values : undefined
    } else {
      newValue = undefined
    }
    setFilter({
      ...currentFilter,
      [value.field]: newValue,
    })
  }
  /* End Chips */

  // Table
  const tableInstance = useShipmentReportTable(
    queryState,
    gotoPage,
    setPageSize,
    toggleSortBy,
    isFetching,
    isSuccess,
    isError,
    data
  )

  const isExportButtonDisabled =
    isFetching || isDownloading || isError || data?.totalResources === 0

  /* Start filter form */
  const onClickFilter = (filterFormData: RebookingDeliveryViewFilter) => {
    setFilter(filterFormData)
  }
  const handleCloseFilterForm = () => {
    setExpandFilterForm(false)
  }
  /* End filter form */

  const handleDownloadModalOpen = odtVersion => {
    setOdtVersionDownload(odtVersion)
    setExportModalOpen(true)
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--eds-space-8)',
          marginBottom: 'var(--eds-space-72)',
        }}
      >
        <div
          className="flex-row flex-nowrap content-space-between justify-items-start"
          style={{ padding: 'var(--eds-space-16) var(--eds-space-16) 0 var(--eds-space-16)' }}
        >
          <div
            style={{
              width: '90%',
            }}
          >
            <Chips allValues={chipsArray} onDelete={handleChipDelete} clearFilter={clearFilter} />
          </div>
          <div>
            <Button
              className="popover-filter"
              style={{
                marginTop: 'var(--eds-space-8)',
                paddingLeft: 'var(--eds-space-16)',
              }}
              onClick={() => setExpandFilterForm(true)}
              beforeSlot={<Icon name="Filter" size="m" />}
            >
              Filters
            </Button>
          </div>
        </div>

        <Divider
          style={{
            width: '99%',
            margin: 'auto',
            borderBottomWidth: 2,
          }}
        />

        <div className="flex-row content-end">
          <ButtonGroup
            variant="outlined"
            ref={anchorRef}
            style={{ marginRight: 'var(--eds-space-16)' }}
          >
            <Button
              className={`eds-button eds-button--primary eds-button--medium ${
                isExportButtonDisabled ? 'eds-button--disabled' : ''
              }`}
              disabled={isExportButtonDisabled}
              onClick={() => handleDownloadModalOpen(false)}
              style={{
                padding:
                  'var(--eds-space-8) var(--eds-space-4) var(--eds-space-8) var(--eds-space-16)',
                borderRadius: 'var(--eds-space-32) 0 0 var(--eds-space-32)',
              }}
              beforeSlot={<Icon name="Download" size="m" />}
            >
              Export{isDownloading && 'ing'} Results
            </Button>
            <ButtonUnstyled
              className={`eds-button eds-button--primary eds-button--medium ${
                isExportButtonDisabled ? 'eds-button--disabled' : ''
              }`}
              disabled={isExportButtonDisabled}
              onClick={() => setPopperOpen(true)}
              style={{
                padding: '10px 10px 10px 5px',
                borderRadius: '0 30px 30px 0',
              }}
              component="button"
            >
              {isDownloading ? <Spinner color="white" /> : <Icon name="CaretDown" />}
            </ButtonUnstyled>
          </ButtonGroup>
          <Popper
            open={popperOpen}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            placement={'bottom-end'}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper elevation={12}>
                  <ClickAwayListener onClickAway={() => setPopperOpen(false)}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      <MenuItem
                        className="eds-type--subtitle-1"
                        onClick={() => handleDownloadModalOpen(true)}
                      >
                        Export ODT Version
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <TableLayout
          {...{ ...tableInstance, pageSizeOptions, isFetching, isError }}
          queryState={queryState}
          className="-striped -highlight"
          refetchData={refetch}
        />
      </div>
      <FilterForm
        currentFilter={queryState.filter}
        onClickFilter={onClickFilter}
        expand={expandFilterForm}
        onCloseSidePanel={handleCloseFilterForm}
      />

      <DownloadModal
        exportModalOpen={exportModalOpen}
        setExportModalOpen={setExportModalOpen}
        queryState={queryState}
        odtVersion={odtVersionDownload}
        isDownloading={isDownloading}
        setIsDownloading={setIsDownloading}
        totalResults={data?.totalResources}
      />
    </>
  )
}

export default withOktaAuth(ShipmentReport)
