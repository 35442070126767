import React from 'react'
import { Modal, ModalProps } from '@nike/eds'
import './SizedModal.css'

interface SizedModalProps extends ModalProps {
  size?: 'small' | 'medium' | 'large'
}

export const SizedModal: React.FC<SizedModalProps> = ({ size = 'medium', ...rest }) => {
  return (
    <>
      <Modal className={`eds-modal__size-${size}`} {...rest} />
    </>
  )
}
