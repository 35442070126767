import { Button, ButtonGroup, Radio, Snack, Snackbar, Spinner, Text } from '@nike/eds'
import { useEffect, useMemo, useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { putJob } from '../../../services/JobService'
import {
  mapStringToArrayOrUndefined,
  valuesAreAllEmails,
  valuesAreAllNumbers,
} from '../../../common/utils/datautils/utils'
import {
  TextField,
  RadioGroup,
  TextArea,
  DatePicker,
  Checkbox as EdsCheckbox,
  InputGroup,
} from '../../../common/components/form'
import { FrequencyField } from '../../../common/components/form/FrequencyField'
import { SizedModal } from '../../../common/components/sizedmodal/SizedModal'
import { useOktaAuth } from '@okta/okta-react'
import { useCarrierHubList } from '../../../common/hooks/useCarrierHubList'
import { CarrierHubTreeDropdown } from '../../../common/components/carrierhubtreedropdown/CarrierHubTreeDropdown'
import { useCountriesList } from '../../../common/hooks/useCountriesList'
import { CountriesAutoComplete } from '../../../common/components/countriesautocomplete/CountriesAutoComplete'
import { Divider } from '@mui/material'
import { reportVersions } from '../Jobs'

const emptyFormData = {
  jobName: '',
  frequency: '0 0 ? * * *',
  reportVersion: 'latest',
  mailTo: [],
  subject: '',
  carrierHubs: [],
  shipToCodes: [],
  iDPPlannedFromTimestamp: '',
  iDPPlannedToTimestamp: '',
  countries: [],
  rebookedCheckbox: '',
  holdCheckbox: '',
  rebooked: false,
  rebookingType: [],
  query: {
    filter: {
      carrierHubs: [],
      shipToCodes: [],
      iDPPlannedFromTimestamp: '',
      iDPPlannedToTimestamp: '',
      countries: [],
      rebookedCheckbox: '',
      holdCheckbox: '',
      rebooked: false,
      rebookingType: [],
      plannedGoodsIssueFromTimestamp: '',
      plannedGoodsIssueToTimestamp: '',
      idpIndicator: undefined,
      idpCheckbox: '',
      nonIdpCheckbox: '',
    },
  },
}

const setIdpIndicator = query => {
  if (query?.idpCheckbox && query?.nonIdpCheckbox) {
    return undefined
  } else if (query?.filter?.idpCheckbox) {
    return true
  } else if (query?.filter?.nonIdpCheckbox) {
    return false
  }
  return undefined
}

const useJobForm = (isOpen, initData, isCountriesCallSuccess, countryData) => {
  const _formData = useMemo(() => {
    const {
      jobName = emptyFormData.jobName,
      frequency = emptyFormData.frequency,
      reportVersion = emptyFormData.reportVersion,
      mailTo = emptyFormData.mailTo,
      subject = emptyFormData.subject,
      query = emptyFormData.query,
    } = initData ?? {}
    return isOpen
      ? {
          jobName,
          frequency,
          reportVersion,
          mailTo,
          subject,
          carrierHubs: query?.filter?.carrierHubs || [],
          shipToCodes: query?.filter?.shipToCodes?.join('\n') ?? emptyFormData.shipToCodes,
          iDPPlannedFromTimestamp: query?.filter?.iDPPlannedFromTimestamp || '',
          iDPPlannedToTimestamp: query?.filter?.iDPPlannedToTimestamp || '',
          plannedGoodsIssueFromTimestamp: query?.filter?.plannedGoodsIssueFromTimestamp || '',
          plannedGoodsIssueToTimestamp: query?.filter?.plannedGoodsIssueToTimestamp || '',
          countries:
            isCountriesCallSuccess && countryData
              ? query?.filter?.shipToCountries?.map(code =>
                  countryData.find(country => country.code === code)
                )
              : emptyFormData.countries,
          rebookedCheckbox: query?.filter?.rebookingType?.includes('REBOOKING'),
          holdCheckbox: query?.filter?.rebookingType?.includes('HOLD'),
          idpCheckbox: query?.filter?.idpCheckbox,
          nonIdpCheckbox: query?.filter?.nonIdpCheckbox,
        }
      : emptyFormData
  }, [isOpen, initData, isCountriesCallSuccess, countryData])

  const { reset, resetField, getValues, ...restForm } = useForm({
    defaultValues: _formData,
    mode: 'all',
  })

  useEffect(() => {
    // refresh the form state whenever we open it
    if (isOpen) {
      reset(_formData)
    }
  }, [isOpen, reset, _formData])

  // extra utility functions...
  const clear = useCallback(() => reset(emptyFormData), [reset])
  const clearField = useCallback(
    (name, options = {}) =>
      resetField(name, { defaultValue: emptyFormData[name] ?? null, ...options }),
    [resetField]
  )

  return {
    ...restForm,
    reset,
    clear,
    resetField,
    clearField,
    getValues,
  }
}

interface JobModalProps {
  selectedJob
  currentJobNames
  refetchData
  jobModalOpen: boolean
  onCloseJobModal: () => void
}

export const JobModal = ({
  selectedJob: initData,
  currentJobNames,
  refetchData,
  jobModalOpen,
  onCloseJobModal,
}: JobModalProps): JSX.Element => {
  const { data: carrierHubData, isSuccess: isCarrierHubCallSuccess } = useCarrierHubList()
  const { data: countryData, isSuccess: isCountriesCallSuccess } = useCountriesList()
  const filterformMethods = useJobForm(jobModalOpen, initData, isCountriesCallSuccess, countryData)
  const { control, handleSubmit, reset } = filterformMethods
  const { authState } = useOktaAuth()
  const [savedJobSnackOpen, setSavedJobSnackOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const createFilter = data => {
    return {
      filter: {
        carrierHubs: data?.carrierHubs.map(e => ({
          carrierAccountCode: e.carrierAccountCode,
          hubCode: e.hubCode,
        })),
        countries: data?.countries?.length ? data?.countries?.map(c => c.code) : undefined,
        shipToCodes: mapStringToArrayOrUndefined(data?.shipToCodes),
        iDPPlannedFromTimestamp: data?.iDPPlannedFromTimestamp,
        iDPPlannedToTimestamp: data?.iDPPlannedToTimestamp,
        rebookedCheckbox: data?.rebookedCheckbox,
        holdCheckbox: data?.holdCheckbox,
        idpCheckbox: data?.idpCheckbox,
        nonIdpCheckbox: data?.nonIdpCheckbox,
        idpIndicator: setIdpIndicator(data),
        plannedGoodsIssueFromTimestamp: data.plannedGoodsIssueFromTimestamp
          ? data.plannedGoodsIssueFromTimestamp
          : undefined,
        plannedGoodsIssueToTimestamp: data.plannedGoodsIssueToTimestamp
          ? data.plannedGoodsIssueToTimestamp
          : undefined,
      },
    }
  }

  const createJob = data => {
    return {
      query: createFilter(data),
      frequency: data.frequency ? data.frequency : undefined,
      reportVersion: data.reportVersion ? data.reportVersion : undefined,
      mailTo: data.mailTo
        ? Array.isArray(data.mailTo)
          ? data.mailTo
          : mapStringToArrayOrUndefined(data.mailTo)
        : undefined,
      subject: data.subject ? data.subject : undefined,
    }
  }

  const saveJob = async data => {
    setIsSaving(true)
    console.log('Saving job with: ' + JSON.stringify(createJob(data)))

    const response = await putJob(authState, data.jobName, createJob(data))
    console.log(response)
    refetchData()
    setIsSaving(false)
    cancel()
    setSavedJobSnackOpen(true)
    setTimeout(() => {
      setSavedJobSnackOpen(false)
    }, 3000)
  }

  const cancel = () => {
    reset()
    onCloseJobModal()
  }

  const formId = 'jobForm'
  return (
    <>
      <FormProvider {...filterformMethods}>
        <SizedModal
          size="large"
          isOpen={jobModalOpen}
          onDismiss={() => cancel()}
          headerSlot={
            <Text font={'title-3'} as={'h3'}>
              Job Details
            </Text>
          }
          footerSlot={
            <ButtonGroup>
              <Button
                variant="primary"
                type="submit"
                size="small"
                form={formId}
                disabled={isSaving}
              >
                {isSaving && <Spinner />}
                {isSaving ? 'Saving...' : 'Save'}
              </Button>

              <Button
                variant="secondary"
                size="small"
                onClick={() => {
                  cancel()
                }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          }
        >
          <form id={formId} className="filter-form" onSubmit={handleSubmit(data => saveJob(data))}>
            <div className="flex-column">
              <div
                className="flex-row flex-nowrap content-start justify-items-center"
                style={{ gap: 'var(--eds-space-16)' }}
              >
                <TextField
                  name={'jobName'}
                  control={control}
                  type={'text'}
                  label="Job Name"
                  message="Must be unique"
                  hideLabel={false}
                  rules={{
                    required: {
                      value: true,
                      message: 'This field is required!',
                    },
                    maxLength: {
                      value: 50,
                      message: 'This field contains too many characters',
                    },
                    validate: input =>
                      !currentJobNames.includes(input) || initData || 'Name already exists',
                  }}
                  disabled={initData}
                  maxChars={50}
                />
                <TextField
                  name={'subject'}
                  control={control}
                  type={'text'}
                  label="Email Subject"
                  hideLabel={false}
                  rules={{
                    required: {
                      value: true,
                      message: 'This field is required!',
                    },
                    maxLength: {
                      value: 50,
                      message: 'This field contains too many characters',
                    },
                  }}
                  maxChars={50}
                />

                <TextArea
                  name="mailTo"
                  control={control}
                  resize="vertical"
                  // @ts-ignore
                  style={{
                    minHeight: '49px',
                    minWidth: '300px',
                    marginBottom: '24px',
                  }}
                  label="Email Recipients"
                  rules={{
                    required: {
                      value: true,
                      message: 'This field is required!',
                    },
                    validate: input =>
                      valuesAreAllEmails(input) || 'Values needs to be email addresses',
                  }}
                />
              </div>

              <div
                className="flex-row flex-nowrap content-start justify-items-center"
                style={{ gap: 'var(--eds-space-16)', maxWidth: '50%' }}
              >
                <InputGroup label="Report Version" clearable={false} withDivider={false}>
                  <RadioGroup control={control} label={'Report Version'} name={'reportVersion'}>
                    {reportVersions.map(version => (
                      <Radio
                        label={version.label}
                        value={version.value}
                        id={`radio-${version.value}`}
                      />
                    ))}
                  </RadioGroup>
                </InputGroup>
                <InputGroup label="Frequency" clearable={false} withDivider={false}>
                  <FrequencyField
                    name="frequency"
                    control={control}
                    label="Frequency"
                    hideLabel={false}
                  />
                </InputGroup>
              </div>
              <Divider style={{ margin: '8px 0px 8px 0px' }} />
              <div
                className="flex-row flex-nowrap content-start justify-items-center"
                style={{ gap: 'var(--eds-space-16)' }}
              >
                <InputGroup label="Carrier / Hub" clearable={false} withDivider={false}>
                  <CarrierHubTreeDropdown
                    name="carrierHubs"
                    control={control}
                    carrierHubs={isCarrierHubCallSuccess && carrierHubData ? carrierHubData : []}
                    showPartiallySelected={true}
                  />
                </InputGroup>
                <InputGroup label="Ship To Country" clearable={false} withDivider={false}>
                  <CountriesAutoComplete
                    name="countries"
                    control={control}
                    countryData={isCountriesCallSuccess && countryData ? countryData : []}
                  />
                </InputGroup>
                <InputGroup label="Ship To Number" clearable={false} withDivider={false}>
                  <TextArea
                    name="shipToCodes"
                    control={control}
                    resize="vertical"
                    label="Ship To Number"
                    rules={{
                      validate: input =>
                        valuesAreAllNumbers(input) ||
                        'Ship To Number can only contain numeric values',
                    }}
                  />
                </InputGroup>
                <InputGroup label="Rebooking Type" clearable={false} withDivider={false}>
                  <EdsCheckbox
                    name="rebookedCheckbox"
                    control={control}
                    label="Rebooked"
                    value="rebooked"
                  />
                  <br />
                  <EdsCheckbox name="holdCheckbox" control={control} label="Hold" value="hold" />
                </InputGroup>
              </div>
              <div
                className="flex-row flex-nowrap content-start justify-items-center"
                style={{ gap: 'var(--eds-space-16)' }}
              >
                <InputGroup label="" clearable={false} withDivider={false}>
                  <DatePicker
                    control={control}
                    from={{
                      name: 'plannedGoodsIssueFromTimestamp',
                    }}
                    to={{
                      name: 'plannedGoodsIssueToTimestamp',
                    }}
                    title={'Planned Goods Issue Date'}
                    advanced={true}
                  />
                </InputGroup>

                <InputGroup label="" clearable={false} withDivider={false}>
                  <DatePicker
                    control={control}
                    from={{
                      name: 'iDPPlannedFromTimestamp',
                    }}
                    to={{
                      name: 'iDPPlannedToTimestamp',
                    }}
                    title={'Expected Delivery Date'}
                    advanced={true}
                  />
                </InputGroup>
                <InputGroup label="IDP Indicator" clearable={false} withDivider={false}>
                  <EdsCheckbox name="idpCheckbox" control={control} label="IDP" value="idp" />
                  <br />
                  <EdsCheckbox
                    name="nonIdpCheckbox"
                    control={control}
                    label="Non-IDP"
                    value="nonIdp"
                  />
                </InputGroup>
              </div>
            </div>
          </form>
        </SizedModal>
      </FormProvider>
      <Snackbar>
        {savedJobSnackOpen && (
          <Snack id="1" status="success" onDismiss={() => setSavedJobSnackOpen(false)}>
            Job saved!
          </Snack>
        )}
      </Snackbar>
    </>
  )
}
