import { useQuery, UseQueryResult } from 'react-query'
import { fetchCarriers, CarrierHubResponse } from '../../services/CarrierConfigService'

export { type CarrierHubResponse } from '../../services/CarrierConfigService'

export const useCarrierHubList = (): UseQueryResult<CarrierHubResponse[], any> => {
  return useQuery(['carriers'], fetchCarriers, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
