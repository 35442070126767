import { SelectAllCell } from '../../common/utils/SelectAllServerRows'
import { createFrequencyStringFromCron } from '../../common/utils/datautils/cron'
import { reportVersions } from './Jobs'

export const jobColumns = [
  {
    id: 'selection',
    width: 20,
    Header: () => <div></div>,
    Cell: props => <SelectAllCell {...props} />,
    disableSortBy: true,
  },
  {
    Header: () => <div>Job Name</div>,
    accessor: 'jobName',
    Cell: item => <div>{item.value}</div>,
    disableSortBy: true,
  },
  {
    Header: () => <div>Frequency</div>,
    accessor: 'frequency',
    Cell: item => <div>{createFrequencyStringFromCron(item.value)}</div>,
    disableSortBy: true,
  },
  {
    Header: () => <div>Report Version</div>,
    accessor: 'reportVersion',
    Cell: item => <div>{reportVersions.find(it => it.value === item.value)?.label}</div>,
    disableSortBy: true,
  },
  {
    Header: () => <div>Email Recipients</div>,
    accessor: 'mailTo',
    Cell: item => <div>{item.value?.join(', ')}</div>,
    disableSortBy: true,
  },
  {
    Header: () => <div>Email Subject</div>,
    accessor: 'subject',
    Cell: item => <div>{item.value}</div>,
    disableSortBy: true,
  },
]
