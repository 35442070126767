import { QueryState } from '../common/hooks/useQueryState'
import Config from '../config/Config'
import { addDays, addMilliseconds } from 'date-fns'
import axios from 'axios'
import { setupAxiosOktaInterceptors } from '../config/AxiosInterceptors'
import { CountryResponse } from '../common/hooks/useCountriesList'
import { checkRelativeDate } from '../common/utils/datautils/utils'
import { zonedTimeToUtc } from 'date-fns-tz'

export const EXPORT_LIMIT = 50000

export const shipmentClient = setupAxiosOktaInterceptors(
  axios.create({
    baseURL: `${Config.BACKEND_URL}/${Config.BASEPATH}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
)

export interface CarrierHub {
  carrierAccountCode?: string
  hubCode?: string
}

export interface RebookingDeliveryViewFilter {
  hubCodes?: string[]
  carrierHubs?: CarrierHub[]
  shipToCodes?: string[]
  iDPPlannedFromTimestamp?: any
  iDPPlannedToTimestamp?: any
  plannedGoodsIssueFromTimestamp?: any
  plannedGoodsIssueToTimestamp?: any
  countries?: string[]
  rebooked?: boolean
  idpIndicator?: boolean
  shipmentReferences?: string[]
  rebookedCheckbox?: boolean
  holdCheckbox?: boolean
  idpCheckbox?: boolean
  nonIdpCheckbox?: boolean
}

function convertToFilter(query: QueryState<RebookingDeliveryViewFilter>) {
  const iDPPlannedFromTimestamp = query.filter?.iDPPlannedFromTimestamp
    ? checkRelativeDate(query.filter?.iDPPlannedFromTimestamp)
      ? query.filter?.iDPPlannedFromTimestamp
      : zonedTimeToUtc(query.filter?.iDPPlannedFromTimestamp, 'Europe/Brussels').toISOString()
    : undefined

  const iDPPlannedToTimestamp = query.filter?.iDPPlannedToTimestamp
    ? checkRelativeDate(query.filter?.iDPPlannedToTimestamp)
      ? query.filter?.iDPPlannedToTimestamp
      : addMilliseconds(
          addDays(zonedTimeToUtc(query.filter?.iDPPlannedToTimestamp, 'Europe/Brussels'), 1),
          -1
        ).toISOString()
    : undefined

  const plannedGoodsIssueFromTimestamp = query.filter?.plannedGoodsIssueFromTimestamp
    ? checkRelativeDate(query.filter?.plannedGoodsIssueFromTimestamp)
      ? query.filter?.plannedGoodsIssueFromTimestamp
      : zonedTimeToUtc(
          query.filter?.plannedGoodsIssueFromTimestamp,
          'Europe/Brussels'
        ).toISOString()
    : undefined

  const plannedGoodsIssueToTimestamp = query.filter?.plannedGoodsIssueToTimestamp
    ? checkRelativeDate(query.filter?.plannedGoodsIssueToTimestamp)
      ? query.filter?.plannedGoodsIssueToTimestamp
      : addMilliseconds(
          addDays(zonedTimeToUtc(query.filter?.plannedGoodsIssueToTimestamp, 'Europe/Brussels'), 1),
          -1
        ).toISOString()
    : undefined

  let rebookingType: any = []
  if (query?.filter?.rebookedCheckbox) {
    rebookingType.push('REBOOKING')
  }
  if (query?.filter?.holdCheckbox) {
    rebookingType.push('HOLD')
  }

  let idpIndicator: any = []
  if (query?.filter?.idpCheckbox) {
    idpIndicator.push(true)
  }
  if (query?.filter?.nonIdpCheckbox) {
    idpIndicator.push(false)
  }

  return {
    ...query?.filter,
    iDPPlannedFromTimestamp: iDPPlannedFromTimestamp,
    iDPPlannedToTimestamp: iDPPlannedToTimestamp,
    plannedGoodsIssueFromTimestamp: plannedGoodsIssueFromTimestamp,
    plannedGoodsIssueToTimestamp: plannedGoodsIssueToTimestamp,
    // convert some fields
    shipToCountries: query?.filter?.countries,
    // remove fields that are not in API
    rebookingType:
      rebookingType.length != null && rebookingType.length > 0 ? rebookingType : undefined,
    rebookedCheckbox: undefined,
    holdCheckbox: undefined,
    idpIndicator:
      idpIndicator.length != null
        ? idpIndicator.length > 1
          ? undefined
          : idpIndicator[0]
        : undefined,
    idpCheckbox: undefined,
    nonIdpCheckbox: undefined,
    countries: undefined,
    packlistStatuses: ['SCHEDULED', 'READY_FOR_SHIPMENT', 'SHIPPED'],
  }
}

function convertToSort(query: QueryState<RebookingDeliveryViewFilter>): string[] {
  if (!query.sortBy) {
    return []
  }

  const sortFieldMapping = new Map([
    ['shipToCode', 'shipTo.code'],
    ['shipToName', 'shipTo.name'],
    ['shipToCountry', 'shipTo.country'],
    ['trailers', 'cmrs.trailerNumber'],
  ])

  return query.sortBy.map(it => `${it.desc ? '-' : ''}${sortFieldMapping.get(it.id) || it.id}`)
}

function getBody(query: QueryState<RebookingDeliveryViewFilter>) {
  return {
    page: query.pageNumber + 1, // backend expects to receive page 1
    size: query.pageSize,
    filter: convertToFilter(query),
    sort: convertToSort(query),
  }
}

function convertToData(data) {
  return data.objects.map(shipment => ({
    shipToCode: shipment?.shipTo.code,
    shipToName: shipment?.shipTo.name,
    shipToCountry: shipment?.shipTo.country,
    shipmentReference: shipment?.shipmentReference,
    carrierAccountCode: shipment?.carrierAccountCode,
    hubCode: shipment?.hubCode,
    totalNumberOfCartons: shipment?.totalNumberOfCartons,
    actualShipDateTime: shipment?.actualShipDateTime,
    iDPPlannedTimestamp: {
      date: shipment?.original?.iDPPlannedTimestamp,
      plannedDeliveryFromTime: shipment?.original?.plannedDeliveryFromTime,
      plannedDeliveryToTime: shipment?.original?.plannedDeliveryToTime,
    },
    plannedGoodsIssueDate: shipment?.plannedGoodsIssueDate,
    deliveryInstructionText: shipment?.original?.deliveryInstructionText,
    rebookingTypeData: {
      type: shipment?.lastApprovedRebooking?.type,
      data: shipment?.lastApprovedRebooking,
    },
    newIDPPlannedTimestamp: {
      date: getNewIDPPlannedTimestamp(shipment),
      plannedDeliveryFromTime: shipment?.lastApprovedRebooking?.plannedDeliveryFromTime,
      plannedDeliveryToTime: shipment?.lastApprovedRebooking?.plannedDeliveryToTime,
    },
    lastChangedDateTime: shipment?.lastApprovedRebooking?.validatedDateTime,
    trailers: getTrailers(shipment),
    iDPIndicator: shipment?.iDPIndicator,
  }))
}

export function getTrailers(shipment) {
  return shipment.cmrs?.map(cmr => ({
    trailerAccessNumber: cmr.trailerAccessNumber,
    numberOfCartons: cmr.numberOfCartons,
    numberOfUnits: cmr.numberOfUnits,
  }))
}

export function getNewIDPPlannedTimestamp(shipment): Date {
  if (
    shipment?.lastApprovedRebooking?.plannedDeliveryFromTime &&
    !shipment?.lastApprovedRebooking?.iDPPlannedTimestamp
  ) {
    return shipment?.original?.iDPPlannedTimestamp
  }
  return shipment?.lastApprovedRebooking?.iDPPlannedTimestamp
}

export const fetchShipments = async (
  query: QueryState<RebookingDeliveryViewFilter>
): Promise<{
  data
  totalResources
  totalPages
}> => {
  console.log('fetchRealShipments with: ' + JSON.stringify(query))
  return shipmentClient.post(`/shipments/v2/search`, getBody(query)).then(response => ({
    data: convertToData(response.data),
    totalResources: response.data.pages.totalResources,
    totalPages: response.data.pages.totalPages,
  }))
}
export interface ExportShipmentReportId {
  shipmentReportId: string
}

export interface ExportShipmentReportTask {
  downloadUrl: string
  expiryDate: Date
  id: string
  status: string
}
export const initiateShipmentReportExportTask = async (
  query: QueryState<RebookingDeliveryViewFilter>,
  version: string | 'latest'
): Promise<ExportShipmentReportId> => {
  const queryClone = { ...query }
  queryClone.pageSize = EXPORT_LIMIT
  console.debug('exportResults with: ' + JSON.stringify(queryClone))

  return shipmentClient
    .post(`/shipments/v2/export`, getBody(queryClone), {
      params: {
        version,
      },
    })
    .then(response => response.data)
}
export const fetchShipmentReportExportTask = async (
  shipmentReportId: string | undefined
): Promise<ExportShipmentReportTask> => {
  return shipmentClient.get(`/report/v1/${shipmentReportId}`).then(response => response.data)
}

export const fetchCountries = async (): Promise<CountryResponse[]> => {
  return shipmentClient.get(`/countries`).then(response => response.data)
}

export const logUsage = async (page: string, action: string, params = {}): Promise<string> => {
  return shipmentClient
    .post('/usage-log', { page, action, application: 'shipment_report', ...params })
    .then(response => response.data)
}
