export const TooltipTrailerInformation = ({ data }) => {
  return (
    <>
      <table className="tooltip-rebooking-content">
        <th colSpan={2}>
          <td>Trailer {data.trailerAccessNumber}</td>
        </th>
        <tr>
          <td># of cartons</td>
          <td>{data.numberOfCartons}</td>
        </tr>
        <tr>
          <td># of units</td>
          <td>{data.numberOfUnits}</td>
        </tr>
      </table>
    </>
  )
}

export default TooltipTrailerInformation
