import { Button, ButtonGroup, Modal, Snack, Snackbar, Spinner, Text } from '@nike/eds'
import { useOktaAuth } from '@okta/okta-react'
import { useState } from 'react'
import 'react-dropdown-tree-select/dist/styles.css'
import { deleteJob } from '../../../services/JobService'
import { logUsage } from '../../../services/ShipmentService'

interface DeleteJobModalProps {
  deleteJobModalOpen: boolean
  onCloseDeleteJobModal: () => void
  selectedRow: any
  refetchData
}

export const DeleteJobModal = ({
  deleteJobModalOpen = false,
  onCloseDeleteJobModal,
  selectedRow,
  refetchData,
}: DeleteJobModalProps): JSX.Element => {
  const { authState } = useOktaAuth()
  const [deleteJobSnackOpen, setDeletedJobSnackOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const handleDeleteJob = async selectedRow => {
    logUsage('delete', 'delete_job')
    setIsDeleting(true)
    deleteJob(authState, selectedRow.original.jobName).then(() => {
      cancel()
      setDeletedJobSnackOpen(true)
      setIsDeleting(false)
      refetchData()

      setTimeout(() => {
        setDeletedJobSnackOpen(false)
      }, 3000)
    })
  }

  const cancel = () => {
    onCloseDeleteJobModal()
  }

  return (
    <>
      <Modal
        disableFocusTrap={true}
        isOpen={deleteJobModalOpen}
        onDismiss={cancel}
        headerSlot={
          <div className="flex-row flex-wrap content-space-between justify-items-center">
            <Text font="title-3">Delete job</Text>
          </div>
        }
        footerSlot={
          <ButtonGroup>
            <Button
              variant="primary"
              type="button"
              size="small"
              onClick={() => handleDeleteJob(selectedRow)}
              disabled={isDeleting}
            >
              {isDeleting && (
                <div className="eds--dark">
                  <Spinner size="medium" />
                </div>
              )}
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
            <Button variant="secondary" onClick={cancel} size="small">
              Cancel
            </Button>
          </ButtonGroup>
        }
      >
        <div>
          <Text font="body-1">{`You are about to delete the job: ${selectedRow?.original.jobName}`}</Text>
        </div>
      </Modal>
      <Snackbar>
        {deleteJobSnackOpen && (
          <Snack id="1" status="success" onDismiss={() => setDeletedJobSnackOpen(false)}>
            Job Deleted!
          </Snack>
        )}
      </Snackbar>
    </>
  )
}
