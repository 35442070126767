import { Routes, Route, useNavigate } from 'react-router-dom'
import ShipmentReport from './shipmentpage/ShipmentReport'
import Jobs from './jobpage/Jobs'
import { LoginCallback } from '@okta/okta-react'
import { Error, OktaError } from '../common/components/error/Error'
import { Button } from '@nike/eds'
import { RequiredAuth } from './okta/SecureRoute'

const AppRoutes: React.FC = (): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Routes>
      <Route path="/" element={<RequiredAuth />}>
        <Route index element={<ShipmentReport />} />
      </Route>
      <Route path="/jobs" element={<RequiredAuth />}>
        <Route index element={<Jobs />} />
      </Route>
      <Route path="implicit/callback" element={<LoginCallback errorComponent={OktaError} />} />
      <Route
        path="*"
        element={
          <Error
            message={'This page does not exist!'}
            actions={<Button onClick={() => navigate('/', { replace: true })}>Back to Home</Button>}
          ></Error>
        }
      />
    </Routes>
  )
}

export default AppRoutes
