import { useEffect } from 'react'
import { IndeterminateCheckbox } from '../components/table/useAllServerRowsSelect'

const defaultIsSelectCheckboxDisabled = row => false

export const SelectAllCell = ({
  row,
  isSelectCheckboxDisabled = defaultIsSelectCheckboxDisabled,
  selectedRows,
  toggleAllRowsSelected,
}) => {
  const checked = row.getToggleRowSelectedProps().checked
  const disabled = isSelectCheckboxDisabled(row)

  useEffect(() => {}, [selectedRows])
  return (
    <div>
      <IndeterminateCheckbox
        {...row.getToggleRowSelectedProps({
          onChange: () => {
            const selected = row.isSelected
            toggleAllRowsSelected(false)
            row.toggleRowSelected(!selected)
          },
        })}
        checked={checked}
        disabled={disabled}
      />
    </div>
  )
}
