import { useQuery, UseQueryResult } from 'react-query'
import { fetchShipments, RebookingDeliveryViewFilter } from '../../services/ShipmentService'
import { QueryState } from './useQueryState'

const shipmentQueries = {
  all: ['shipments'],
  lists: ['shipments', 'list'],
  list: queryState => [...shipmentQueries.lists, queryState],
}

export const useShipmentList = (
  queryState: QueryState<RebookingDeliveryViewFilter>
): UseQueryResult<{
  data
  totalResources
  totalPages
}> => {
  return useQuery(shipmentQueries.list(queryState), () => fetchShipments(queryState), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
}
