import React, { useEffect, useRef } from 'react'
import { TextField } from '@nike/eds'
import { useController, UseControllerProps, useFormContext, useWatch } from 'react-hook-form'
import { calculateDate, checkRelativeDate } from '../../utils/datautils/utils'
import { date } from '../../utils/datautils/formatter'

export interface RelativeDateFieldProps
  extends Omit<
      React.ComponentPropsWithoutRef<typeof TextField>,
      'id' | 'name' | 'value' | 'defaultValue' | 'onBlur'
    >,
    Omit<UseControllerProps, 'control'> {
  control: any
}

function getText(relativeDate) {
  if (!relativeDate?.length) return ''

  const match = checkRelativeDate(relativeDate)

  if (match) {
    return `${match.groups.sign === '-' ? '-' : ''}${match.groups.value}`
  }
  return ''
}

export const RelativeDateField = ({
  name,
  control,
  type = 'text',
  label,
  rules = undefined,
  message,
  errorMessage,
  ...rest
}: RelativeDateFieldProps) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useFormContext()
  const relativeDateFieldValue = getValues(name)

  const {
    field: { onChange: onChangeText, value: valueText, ...fieldPropsText },
  } = useController({
    name: `${name}Text`,
    control,
    defaultValue: getText(relativeDateFieldValue),
    shouldUnregister: true,
  })

  register(name, rules)
  const value = useWatch({ name, control })

  const isFrom = () => {
    return name.toLowerCase().includes('from')
  }

  const initialized = useRef(false)
  useEffect(() => {
    // make sure we don't override value when it is not initialized yet
    if (!initialized.current) {
      // make sure we run this hook after first initialization only
      initialized.current = true
      return
    }

    const sign = valueText >= '0' ? '+' : ''
    const datePart = !!valueText?.length ? 'd' : ''
    setValue(name, `${sign}${valueText}${datePart}`, {
      shouldValidate: true,
    })
  }, [valueText, setValue, name])

  return (
    <TextField
      id={name}
      {...fieldPropsText}
      value={valueText}
      onChange={e => {
        // hack to only allow values in -1000 to 1000 range
        const targetValue = e.target.value
        if (Number(targetValue) > 1000 || Number(targetValue) < -1000) {
          e.preventDefault()
          return
        }
        onChangeText(targetValue)
      }}
      onKeyDown={e => {
        // don't allow decimals or exponents
        if (['.', ',', 'E', 'e'].includes(e.key)) e.preventDefault()
      }}
      label={label}
      type="number"
      hasErrors={!isValid && !!errors[name]}
      errorMessage={(errors[name]?.message as string) ?? ''}
      message={`${message} ${value ? `${date(calculateDate(value, isFrom()), true)}` : ''}`}
      afterSlot="days"
      style={{ textAlign: 'right' }}
      {...rest}
    />
  )
}
