import Config from '../config/Config'
import axios from 'axios'
import { setupAxiosOktaInterceptors } from '../config/AxiosInterceptors'

export const userPreferencesClient = setupAxiosOktaInterceptors(
  axios.create({
    baseURL: `${Config.USER_PREFERENCES_URL}/${Config.BASEPATH_USER_PREFERENCES_CONFIG}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
)

export interface CarrierHub {
  carrierAccountCode?: string
  hubCode?: string
}

export interface RebookingDeliveryViewFilter {
  hubCodes?: string[]
  carrierHubs?: CarrierHub[]
  shipToCodes?: string[]
  iDPPlannedFromTimestamp?: any
  iDPPlannedToTimestamp?: any
  countries?: string[]
  rebooked?: boolean
  shipmentReferences?: string[]
  rebookedCheckbox?: boolean
  holdCheckbox?: boolean
}

export interface ApplicationSettings {
  filters: ApplicationFilter[]
}
export interface ApplicationFilter {
  name?: string
  default?: boolean
  filter: RebookingDeliveryViewFilter
}
export const fetchUserPreferences = async (
  authState: any,
  component: string,
  key: string
): Promise<ApplicationSettings> => {
  return userPreferencesClient
    .get(
      `/user-preferences/${authState?.accessToken?.claims?.sub}/applications/${Config.NAME}/${component}/${key}`
    )
    .then(response => response.data ?? [])
}

export const updateUserPreferences = async (
  authState: any,
  filters: any,
  component: string,
  key: string
): Promise<ApplicationSettings> => {
  return userPreferencesClient
    .put(
      `/user-preferences/${authState?.accessToken?.claims?.sub}/applications/${Config.NAME}/${component}/${key}`,
      filters
    )
    .then(response => response.data ?? [])
}

export const logUsage = async (page: string, action: string, params = {}): Promise<string> => {
  return userPreferencesClient
    .post('/usage-log', { page, action, application: 'shipment_report', ...params })
    .then(response => response.data)
}
