import { useReducer, useCallback, useMemo } from 'react'
import { isEqual } from 'lodash'

export const actions = {
  setFilter: 'set-filter',
  clearFilter: 'clear-filter',
  clearQuery: 'clear-query',
  setPagination: 'set-pagination',
  toggleSortBy: 'toggle-sortBy',
}

export const defaultQueryState: QueryState<any> = {
  pageNumber: 0,
  pageSize: 25,
  sortBy: [{ id: 'lastChangedDateTime', desc: true }],
}

interface SortBy {
  id: string
  desc: boolean
}

export interface QueryState<F> {
  filter?: F
  pageNumber: number
  pageSize: number
  sortBy: SortBy[]
}

interface Action<F> {
  type: string
  initState: QueryState<F>
  [key: string]: unknown
}

const isChangeRequired = (state, action) => {
  switch (action.type) {
    case actions.setFilter:
      return !isEqual(state.filter, action.filter)
    case actions.clearFilter:
      return state.filter !== action.initState.filter
    case actions.clearQuery:
      return state !== action.initState
    case actions.setPagination:
      return (
        (!isNaN(action?.pageNumber) && state.pageNumber !== action?.pageNumber) ||
        (!isNaN(action?.pageSize) && state.pageSize !== action.pageSize)
      )
    case actions.toggleSortBy:
      return !state.sortBy.find((it: SortBy) => it.id === action.id && it.desc === action.desc)
    default:
      return true
  }
}

const queryReducer = <F>(state, action: Action<F>) => {
  if (!isChangeRequired(state, action)) {
    return state
  }
  switch (action.type) {
    case actions.setFilter:
      return {
        ...state,
        filter: action.filter,
        pageNumber: action.initState.pageNumber, // perhaps we should use the initState instead
      }
    case actions.clearFilter:
      return {
        ...state,
        filter: action.initState.filter,
        pageNumber: action.initState.pageNumber,
      }
    case actions.clearQuery:
      return action.initState
    case actions.setPagination:
      return {
        ...state,
        pageNumber: action.pageNumber !== undefined ? action.pageNumber : state.pageNumber,
        pageSize: action.pageSize || state.pageSize,
      }
    case actions.toggleSortBy:
      return {
        ...state,
        sortBy: [
          {
            id: action.id,
            desc: action.desc,
          },
        ],
      }
    default:
      throw new Error('Unsupported action for queryReducer')
  }
}
export const useQueryState = <F>(initStateOverwrites: Partial<QueryState<F>> = {}) => {
  const initState = useMemo(
    () => ({
      ...defaultQueryState,
      ...initStateOverwrites,
    }),
    [initStateOverwrites]
  )

  const [state, dispatch] = useReducer(queryReducer, initState)

  const gotoPage = useCallback(
    pageNumber =>
      dispatch({
        type: actions.setPagination,
        pageNumber,
        initState,
      }),
    [dispatch, initState]
  )

  const setPageSize = useCallback(
    pageSize =>
      dispatch({
        type: actions.setPagination,
        pageSize,
        initState,
      }),
    [dispatch, initState]
  )

  const toggleSortBy = useCallback(
    (id, sortByDesc) =>
      dispatch({
        type: actions.toggleSortBy,
        id,
        desc: sortByDesc,
        initState,
      }),
    [dispatch, initState]
  )

  const setFilter = useCallback(
    filter =>
      dispatch({
        type: actions.setFilter,
        filter,
        initState,
      }),
    [dispatch, initState]
  )

  const clearFilter = useCallback(
    () => dispatch({ type: actions.clearFilter, initState }),
    [dispatch, initState]
  )

  const clearQuery = useCallback(
    () => dispatch({ type: actions.clearQuery, initState }),
    [dispatch, initState]
  )

  return {
    state,
    gotoPage,
    setPageSize,
    toggleSortBy,
    setFilter,
    clearFilter,
    clearQuery,
  }
}

export default useQueryState
