import { useQuery, UseQueryResult } from 'react-query'
import { fetchJobs, Job } from '../../services/JobService'

interface JobResponse {
  data: Job[]
  totalResources
}

const jobQueries = {
  all: ['jobs'],
  lists: ['jobs', 'list'],
  list: queryState => [...jobQueries.lists, queryState],
}

export const useJobList = (
  authState
): UseQueryResult<
  JobResponse,
  {
    data
    totalResources
  }
> => {
  return useQuery(['jobs'], () => fetchJobs(authState), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
}
