import { Button, Icon, Spinner, Table, TableCell, TableHeading, Text } from '@nike/eds'
import { TableInstance } from 'react-table'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import './TableLayout.css'
import { MenuItem } from '@mui/material'
import { Error } from '../../components/error/Error'
import { TablePagination } from './TablePagination'
import React from 'react'

interface TableLayoutProps extends TableInstance {
  pageSizeOptions: number[]
  totalResources: number
  isFetching: boolean
  isError: boolean
  refetchData: () => void
  hasPagination?: boolean
}

const FetchingError = ({ refetchData }) => {
  return (
    <Error
      message={'There was an issue fetching the table data. Please try again!'}
      actions={<Button onClick={() => refetchData()}>Refresh table</Button>}
    />
  )
}

const FetchingData = () => (
  <div className="flex-row flex-wrap content-center justify-items-center">
    <div style={{ marginRight: 'var(--eds-space-8)' }}>
      <Spinner size="large" />
    </div>
    <div>
      <Text>Fetching Data</Text>
    </div>
  </div>
)

const NoData = ({ refetchData }) => (
  <Error
    title="No Data Found"
    message={'Please use other criterias or refresh the table later'}
    actions={<Button onClick={() => refetchData()}>Refresh table</Button>}
  />
)

const TableWithoutPagination = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  prepareRow,
  page,
  toggleSortBy,
}) => {
  return (
    <div
      style={{
        overflowX: 'clip',
      }}
    >
      <Table isDark={false} {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map(headerGroup => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <TableHeading
                      {...column.getHeaderProps({
                        style: { minWidth: column.minWidth, width: column.width },
                      })}
                    >
                      {
                        // Render the header
                        //if column is sortable
                        column.canSort ? (
                          <div
                            {...column.getSortByToggleProps({
                              style: { minWidth: column.minWidth, width: column.width },
                            })}
                            onClick={() => {
                              toggleSortBy(
                                column.id,
                                column.isSorted ? !column.isSortedDesc : false
                              )
                            }}
                          >
                            <div className="flex-row flex-nowrap content-space-between justify-items-center">
                              <div>{column.render('Header')}</div>
                              <div>
                                {/* Add a sort direction indicator */}
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <Icon
                                      name="SortDescending"
                                      size="m"
                                      className="TableSortIconDesc"
                                    />
                                  ) : (
                                    <Icon
                                      name="SortAscending"
                                      size="m"
                                      className="TableSortIconAsc"
                                    />
                                  )
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          //if column is not sortable
                          <div>{column.render('Header')}</div>
                        )
                      }
                    </TableHeading>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}

        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            page.map(row => {
              // Prepare the row for display
              prepareRow(row)
              const { key, ...rowProps } = row.getRowProps()
              return (
                // Apply the row props
                <React.Fragment key={key}>
                  <tr {...rowProps}>
                    {
                      // Loop over the rows cells
                      row.cells.map(cell => {
                        // Apply the cell props
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </TableCell>
                        )
                      })
                    }
                  </tr>
                </React.Fragment>
              )
            })
          }
        </tbody>
      </Table>
    </div>
  )
}

export const TableLayout = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  prepareRow,
  page,
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  setPageSize,
  toggleSortBy,
  state: { pageIndex, pageSize },
  pageSizeOptions,
  totalResources,
  isFetching,
  isError,
  refetchData,
  hasPagination = true,
}: TableLayoutProps): JSX.Element => {
  const changePageSize = (event: SelectChangeEvent<string>) => {
    setPageSize(event.target.value as unknown as number)
    gotoPage(0)
  }
  // Render the UI for your table
  let isFewRecords = false
  if (totalResources < pageSize) {
    isFewRecords = true
  }

  let isLastPage = false
  if (pageIndex === pageCount - 1) {
    isLastPage = true
  }

  return (
    <>
      {hasPagination && (
        <div>
          {isError && <FetchingError refetchData={refetchData} />}
          {!isError && isFetching && <FetchingData />}
          {!isError && !isFetching && totalResources === 0 && <NoData refetchData={refetchData} />}
          {!isError && !isFetching && totalResources > 0 && (
            <>
              <div
                className="flex-row flex-wrap content-center justify-items-center TableLayoutPagination"
                style={{ marginTop: 'calc(-1 * var(--eds-space-72))' }}
              >
                <TablePagination
                  {...{ pageIndex, pageCount, canPreviousPage, canNextPage, gotoPage, refetchData }}
                  style={{ height: 'var(--eds-space-56)' }}
                />
              </div>
              <TableWithoutPagination
                getTableProps={getTableProps}
                headerGroups={headerGroups}
                getTableBodyProps={getTableBodyProps}
                prepareRow={prepareRow}
                page={page}
                toggleSortBy={toggleSortBy}
              ></TableWithoutPagination>

              <div className="flex-row flex-wrap content-space-between justify-items-center TableLayoutPagination">
                <div>
                  <Text font="body-2" as="span">
                    {totalResources === 0 ? 0 : pageSize * (pageIndex + 1) - (pageSize - 1)} -{' '}
                    {isFewRecords
                      ? totalResources
                      : isLastPage
                      ? totalResources
                      : pageSize * (pageIndex + 1)}{' '}
                    of {totalResources} results
                  </Text>
                </div>

                <TablePagination
                  {...{ pageIndex, pageCount, canPreviousPage, canNextPage, gotoPage, refetchData }}
                  style={{ height: 'var(--eds-space-56)' }}
                />

                <div>
                  <Text font="body-2" as="span" style={{ marginRight: 'var(--eds-space-4)' }}>
                    Rows per page:{' '}
                  </Text>
                  <Select
                    value={pageSize?.toString()}
                    onChange={changePageSize}
                    style={{ marginRight: 'var(--eds-space-16)' }}
                  >
                    {pageSizeOptions.map(it => (
                      <MenuItem key={it} value={it}>
                        {it}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {!hasPagination && (
        <div>
          {!isError && isFetching && <FetchingData />}
          {!isError && !isFetching && totalResources === 0 && <NoData refetchData={refetchData} />}
          {!isError && !isFetching && totalResources > 0 && (
            <TableWithoutPagination
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              getTableBodyProps={getTableBodyProps}
              prepareRow={prepareRow}
              page={page}
              toggleSortBy={toggleSortBy}
            ></TableWithoutPagination>
          )}
        </div>
      )}
    </>
  )
}
