import { Text, Toggle } from '@nike/eds'
import { useFormContext, useWatch } from 'react-hook-form'
import './DatePicker.css'
import { TextField } from '.'
import { useEffect, useState } from 'react'
import { RelativeDateField } from './RelativeDateField'
import { calculateDate, checkRelativeDate } from '../../utils/datautils/utils'

interface DatePickerProps {
  from: DatepickerDate
  to: DatepickerDate
  title: string
  control: any
  advanced?: boolean
}

interface DatepickerDate {
  name: string
}

export const DatePicker = ({ control, from, to, title, advanced = false }: DatePickerProps) => {
  let fromValue = useWatch({ control, name: from.name })
  let toValue = useWatch({ control, name: to.name })
  const [activeTab, setActiveTab] = useState('absolute')

  const {
    formState: { errors, isValid },
    getValues,
    setValue,
    clearErrors,
  } = useFormContext()

  useEffect(() => {
    if (fromValue !== '' || toValue !== '') {
      const fromValueIsRelative = checkRelativeDate(fromValue)
      const toValueIsRelative = checkRelativeDate(toValue)
      const hasRelative = fromValueIsRelative || toValueIsRelative
      if (hasRelative && activeTab === 'absolute') {
        setActiveTab('relative')
      } else if (!hasRelative && activeTab === 'relative') {
        setActiveTab('absolute')
      }
    }
  }, [activeTab, fromValue, toValue])

  const switchTab = id => {
    setValue(from.name, '')
    clearErrors(from.name)
    setValue(to.name, '')
    clearErrors(to.name)
    setActiveTab(id)
  }

  const validateTimeWindow = () => {
    const fromValue = getValues(from.name)
    const toValue = getValues(to.name)

    if (fromValue === '' && toValue === '') {
      return true
    }
    if (fromValue !== '' && toValue !== '' && activeTab === 'absolute') {
      return fromValue <= toValue
    }
    if (fromValue !== '' && toValue !== '' && activeTab === 'relative') {
      return calculateDate(fromValue, true) < calculateDate(toValue, false)
    }

    return true
  }

  return (
    <>
      {advanced && (
        <div
          className="flex-row flex-nowrap content-start justify-items-center"
          style={{ gap: 'var(--eds-space-32)', marginBottom: 'var(--eds-space-4)' }}
        >
          <Text font="title-6" style={{ width: '225px' }}>
            {title}
          </Text>
          <Toggle
            id={title}
            label="Relative"
            size="medium"
            onClick={() => switchTab(activeTab === 'relative' ? 'absolute' : 'relative')}
            checked={activeTab === 'relative'}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      )}

      {activeTab === 'absolute' && (
        <div className="flex-column">
          {!advanced && <Text font="title-6">{title}</Text>}
          <div
            className="flex-row flex-nowrap content-start justify-items-center"
            style={{ gap: 'var(--eds-space-8)' }}
          >
            {/* Avoid issue where the date format is not valid */}
            {!checkRelativeDate(fromValue) && (
              <TextField
                name={from.name}
                control={control}
                type="date"
                onKeyDown={e => e.preventDefault()}
                label=""
                hideLabel={true}
                placeholder="From"
                aria-label={`From ${title}`}
                max={toValue}
                rules={{
                  validate: () => validateTimeWindow() || ' ', // whitespace to make sure default Error text is not shown
                }}
                style={{ paddingBottom: 'var(--eds-space-8)', paddingTop: 'var(--eds-space-8)' }}
              />
            )}

            {/* Avoid issue where the date format is not valid */}
            {!checkRelativeDate(toValue) && (
              <TextField
                name={to.name}
                control={control}
                type="date"
                onKeyDown={e => e.preventDefault()}
                label=""
                hideLabel={true}
                placeholder="To"
                aria-label={`To ${title}`}
                min={fromValue}
                rules={{
                  validate: () => validateTimeWindow() || ' ', // whitespace to make sure default Error text is not shown
                }}
                style={{ paddingBottom: 'var(--eds-space-8)', paddingTop: 'var(--eds-space-8)' }}
              />
            )}
          </div>
        </div>
      )}

      {activeTab === 'relative' && (
        <div
          className="flex-row flex-nowrap content-space-between justify-items-start"
          style={{ gap: 'var(--eds-space-8)' }}
        >
          <RelativeDateField
            name={from.name}
            control={control}
            label=""
            hideLabel={true}
            message="From"
            rules={{
              validate: () => validateTimeWindow() || ' ', // whitespace to make sure default Error text is not shown
            }}
            className="generalWidth"
            style={{ paddingBottom: 'var(--eds-space-8)', paddingTop: 'var(--eds-space-8)' }}
          />
          <RelativeDateField
            name={to.name}
            control={control}
            label=""
            hideLabel={true}
            message="To"
            rules={{
              validate: () => validateTimeWindow() || ' ', // whitespace to make sure default Error text is not shown
            }}
            className="generalWidth"
            style={{ paddingBottom: 'var(--eds-space-8)', paddingTop: 'var(--eds-space-8)' }}
          />
        </div>
      )}
      {activeTab === 'relative' && !isValid && (errors[from.name] || errors[to.name]) && (
        <div className="flex-row justify-items-center " style={{ maxWidth: '60em' }}>
          <Text font="body-2" className="eds-color--danger">
            From value should be before To value
          </Text>
        </div>
      )}
    </>
  )
}
