export const frequencyOptions = [
  { value: '1d', label: 'Once a day' },
  { value: '2d', label: 'Twice a day' },
  { value: '1w', label: 'Once a week' },
]

export const hourOptions = [
  { value: '0', label: '00:00' },
  { value: '1', label: '01:00' },
  { value: '2', label: '02:00' },
  { value: '3', label: '03:00' },
  { value: '4', label: '04:00' },
  { value: '5', label: '05:00' },
  { value: '6', label: '06:00' },
  { value: '7', label: '07:00' },
  { value: '8', label: '08:00' },
  { value: '9', label: '09:00' },
  { value: '10', label: '10:00' },
  { value: '11', label: '11:00' },
  { value: '12', label: '12:00' },
  { value: '13', label: '13:00' },
  { value: '14', label: '14:00' },
  { value: '15', label: '15:00' },
  { value: '16', label: '16:00' },
  { value: '17', label: '17:00' },
  { value: '18', label: '18:00' },
  { value: '19', label: '19:00' },
  { value: '20', label: '20:00' },
  { value: '21', label: '21:00' },
  { value: '22', label: '22:00' },
  { value: '23', label: '23:00' },
]

export const dayOptions = [
  { value: '2', label: 'Monday' },
  { value: '3', label: 'Tuesday' },
  { value: '4', label: 'Wednesday' },
  { value: '5', label: 'Thursday' },
  { value: '6', label: 'Friday' },
  { value: '7', label: 'Saturday' },
  { value: '1', label: 'Sunday' },
]

interface CronFields {
  firstHour: string
  secondHour: string
  dayOfWeek: string
  frequencyOption: string
}
export const extractCronFields = (cron: string): CronFields => {
  let dayOfWeek = '*'
  let firstHour = '0'
  let secondHour = '0'
  let frequencyOption = frequencyOptions[0].value //default once a day

  if (cron) {
    const values = cron.split(' ')
    if (values[1].includes(',')) {
      firstHour = hourOptions[values[1].split(',')[0]].value
      secondHour = hourOptions[values[1].split(',')[1]].value
      frequencyOption = frequencyOptions[1].value //twice a day
    } else {
      firstHour = hourOptions[values[1]].value
    }

    if (values[4] !== '*') {
      dayOfWeek = dayOptions.find(it => it.value === values[4])?.value ?? dayOfWeek
      frequencyOption = frequencyOptions[2].value //once a week
    }
  }

  return {
    firstHour,
    secondHour,
    dayOfWeek,
    frequencyOption,
  }
}

export const makeCronFromFields = ({
  firstHour,
  secondHour,
  dayOfWeek,
  frequencyOption,
}: CronFields): string => {
  if (frequencyOption === '1d') {
    return `0 ${firstHour} ? * * *`
  } else if (frequencyOption === '2d') {
    return `0 ${firstHour},${secondHour} ? * * *`
  } else if (frequencyOption === '1w') {
    return `0 ${firstHour} ? * ${dayOfWeek} *`
  }

  return '0 0 ? * * *'
}

export const createFrequencyStringFromCron = (cron: string) => {
  const { firstHour, secondHour, dayOfWeek, frequencyOption } = extractCronFields(cron)

  let frequencyString = ''

  switch (frequencyOption) {
    case '1d':
      frequencyString += `Once a day at ${hourOptions.find(it => it.value === firstHour)?.label}`
      break
    case '2d':
      frequencyString += `Twice a day at ${
        hourOptions.find(it => it.value === firstHour)?.label
      } and ${hourOptions.find(it => it.value === secondHour)?.label}`
      break
    case '1w':
      frequencyString += `${dayOptions.find(it => it.value === dayOfWeek)?.label} at ${
        hourOptions.find(it => it.value === firstHour)?.label
      }`
      break
  }

  return frequencyString
}
