import { Link, Text } from '@nike/eds'
import clsx from 'clsx'
import './Chips.css'
import Chip from '@mui/material/Chip'
import { useState } from 'react'

export interface ChipValue {
  field: string
  value: any
  stringValue: string
  index?: number
}

export interface ChipsProps {
  allValues: ChipValue[]
  onDelete: (value: ChipValue) => void
  clearFilter: () => void
  className?: string
}

export const Chips = ({ allValues, onDelete, clearFilter, className = '' }: ChipsProps) => {
  const MAX_CHIPS_LENGTH = 8
  const enrichedClassName = clsx('filter-chips', className)
  const [showAllCarrierHubs, setShowAllCarrierHubs] = useState<boolean>(false)
  const [showAllShipToCodes, setShowAllShipToCodes] = useState<boolean>(false)
  const [showAllCountries, setShowAllCountries] = useState<boolean>(false)
  const [showAllShipmentReferences, setShowAllShipmentReferences] = useState<boolean>(false)
  const [showAllOther, setShowAllOther] = useState<boolean>(false)

  const carrierHubChips = allValues.filter(e => e.field === 'carrierHubs')

  const shipToCodes = allValues.filter(e => e.field === 'shipToCodes')

  const countries = allValues.filter(e => e.field === 'countries')

  const shipmentReferences = allValues.filter(e => e.field === 'shipmentReferences')

  const other = allValues.filter(
    e =>
      e.field !== 'carrierHubs' &&
      e.field !== 'shipToCodes' &&
      e.field !== 'countries' &&
      e.field !== 'shipmentReferences'
  )

  const createChipsArray = (values: ChipValue[], show: boolean, showAll, field: string) => {
    return (
      <div>
        {values.slice(0, show ? values.length : MAX_CHIPS_LENGTH).map((item, key) => (
          <Chip
            className="customChip"
            variant="filled"
            style={{ backgroundColor: 'var(--eds-color-background-default)' }}
            label={item.stringValue}
            key={`${item.field}-${key}`}
            onDelete={() => {
              onDelete(item)
            }}
          />
        ))}
        {show && values.length > MAX_CHIPS_LENGTH && (
          <Chip
            onClick={() => showAll(false)}
            label={'See Less'}
            variant="outlined"
            style={{ margin: 'var(--eds-space-4)' }}
          />
        )}
        {!show && values.length > MAX_CHIPS_LENGTH && (
          <Chip
            onClick={() => showAll(true)}
            label={`+${values.length - MAX_CHIPS_LENGTH}`}
            variant="outlined"
            style={{ margin: 'var(--eds-space-4)' }}
          />
        )}
      </div>
    )
  }

  return (
    <div className={enrichedClassName}>
      <div className="filter-chips-action">
        <Text font="title-5" className="eds-spacing--mb-8">
          Filters ({allValues?.length})
        </Text>
        <Link as="button" variant="secondary" onClick={clearFilter}>
          Clear All
        </Link>
      </div>

      <div className="filter-chips-group">
        {carrierHubChips.length > 0 &&
          createChipsArray(
            carrierHubChips,
            showAllCarrierHubs,
            setShowAllCarrierHubs,
            'carrierHubs'
          )}

        {shipToCodes.length > 0 &&
          createChipsArray(shipToCodes, showAllShipToCodes, setShowAllShipToCodes, 'shipToCodes')}

        {countries.length > 0 &&
          createChipsArray(countries, showAllCountries, setShowAllCountries, 'countries')}

        {shipmentReferences.length > 0 &&
          createChipsArray(
            shipmentReferences,
            showAllShipmentReferences,
            setShowAllShipmentReferences,
            'shipmentReferences'
          )}

        {other.length > 0 && createChipsArray(other, showAllOther, setShowAllOther, 'other')}
      </div>
    </div>
  )
}

export default Chips
