import { Box, Button, StatusIcon, Text } from '@nike/eds'
import { useDownloadShipmentReport } from '../../../common/hooks/useDownloadExportQueries'
import { useEffect, useState } from 'react'
import { QueryState } from '../../../common/hooks/useQueryState'
import {
  EXPORT_LIMIT,
  logUsage,
  RebookingDeliveryViewFilter,
} from '../../../services/ShipmentService'
import { SizedModal } from '../../../common/components/sizedmodal/SizedModal'

interface DownloadModalInput {
  exportModalOpen: boolean
  setExportModalOpen: (data: boolean) => void
  queryState: QueryState<RebookingDeliveryViewFilter>
  odtVersion: boolean
  isDownloading: boolean
  setIsDownloading: (data: boolean) => void
  totalResults?: number
}
export const DownloadModal = ({
  exportModalOpen,
  setExportModalOpen,
  queryState,
  odtVersion,
  isDownloading,
  setIsDownloading,
  totalResults,
}: DownloadModalInput): JSX.Element => {
  const [startTime, setStartTime] = useState<Date>()
  const [downloadQueryState, setDownloadQueryState] =
    useState<QueryState<RebookingDeliveryViewFilter>>(queryState)
  const download = () => {
    logUsage('view', 'export', { exportVersion: odtVersion ? 'odt' : 'latest' })
    if (!isDownloading) {
      setDownloadQueryState(queryState)
    }
    setIsDownloading(true)
    setExportModalOpen(false)
  }
  const cancel = () => {
    setIsDownloading(false)
    setExportModalOpen(false)
  }
  useEffect(() => {
    if (isDownloading) {
      setStartTime(new Date())
    }
    if (!isDownloading) {
      setStartTime(undefined)
    }
  }, [isDownloading])

  useDownloadShipmentReport(
    downloadQueryState,
    odtVersion ? 'odt' : 'latest',
    isDownloading,
    setIsDownloading,
    startTime
  )
  return (
    <>
      <SizedModal
        isOpen={exportModalOpen}
        onDismiss={() => setExportModalOpen(false)}
        headerSlot={
          <Text font={'title-3'} as={'h3'}>
            Export Results
          </Text>
        }
        footerSlot={
          <div
            style={{
              display: 'grid',
              gridGap: '10px',
              gridTemplateColumns: 'auto auto',
              justifyContent: 'start',
            }}
          >
            <Button onClick={() => download()} size="small" className="eds--dark">
              Download
            </Button>
            <Button onClick={() => cancel()} size="small" variant="secondary">
              Cancel
            </Button>
          </div>
        }
      >
        <Box>
          <Text>You're about to export the results. This can take a while.</Text>
          <Text style={{ marginTop: 'var(--eds-space-16)' }}>
            Once the application has generated the report it will be automatically downloaded.
          </Text>
          {!!(totalResults && totalResults > EXPORT_LIMIT) && (
            <Text style={{ marginTop: 'var(--eds-space-16)' }}>
              Please note that only the first {EXPORT_LIMIT} results will be exported.
            </Text>
          )}
          {!!odtVersion && (
            <div className="flex-row" style={{ marginTop: 'var(--eds-space-16)' }}>
              <StatusIcon status="warning" style={{ marginRight: 'var(--eds-space-16)' }} />
              <Text font="legal-1">
                You're about to download the report in ODT format. It's not recommended to use this
                format anymore as some business references are truncated (leading zero(es) dropped
                for packlist number, shipTo) which is preventing you to directly reuse this
                information in other system (eg. rebook a shipment, see shipment details and status,
                etc.). If you rely on this format with some macros or any other scripts, we highly
                recommend you to migrate to the new format as soon as possible.
              </Text>
            </div>
          )}
        </Box>
      </SizedModal>
    </>
  )
}
