import { AppHeader, Avatar, Button, Icon, IconButton, Link } from '@nike/eds'
import AppRoutes from '../AppRoutes'
import './index.css'
import { useOktaAuth } from '@okta/okta-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useRoleContext from '../../common/hooks/useRoleContext'
import { Banner } from '../../common/components/banner/Banner'
import Config from '../../config/Config'

function getInitials(name: string) {
  const matchedFirstLetters = name.match(/(\b[A-Z])/g)
  if (!matchedFirstLetters || matchedFirstLetters.length < 2) {
    // Fallback: take first letter
    return name[0]?.toUpperCase()
  }
  return `${matchedFirstLetters.shift()}${matchedFirstLetters.pop()}`
}

export const Layout = () => {
  const { authState } = useOktaAuth()
  const navigate = useNavigate()

  const initials = useMemo(() => {
    const name = authState?.idToken?.claims?.name
    return name ? getInitials(name) : '-'
  }, [authState])

  const env = Config.ENV
  const appName = env === 'PR' ? 'Shipment Report' : `Shipment Report (${env})`

  const authenticatedProps = {
    avatarSlot: <Avatar initials={initials} size={36} />,
    toolbarSlot: (
      <>
        <Button
          variant="ghost"
          disableRipple
          className="eds-spacing--mr-4"
          onClick={() => window.open('slack://channel?team=T0G3T5X2B&id=C04TFCUSBAS', '_self')}
        >
          #ask-maverick
        </Button>
        {useRoleContext().hasJobAccess() && (
          <IconButton
            icon="Settings"
            label="Jobs"
            variant="ghost"
            onClick={() => navigate('/jobs', { replace: true })}
          />
        )}
      </>
    ),
  }

  return (
    <div className="layout">
      <header className="header">
        <AppHeader
          isDark
          appName={appName}
          logoSlot={
            <Link>
              <Icon
                name="NikeSwoosh"
                size="l"
                color="var(--eds-color-white)"
                className="nike-app-icon"
                onClick={() => navigate('/', { replace: true })}
              />
            </Link>
          }
          {...(authState?.isAuthenticated && { ...authenticatedProps })}
        />
      </header>
      <Banner />
      <main className="content">
        <AppRoutes />
      </main>
    </div>
  )
}

export default Layout
