import { add, lastDayOfMonth, lastDayOfWeek, set, setDate, setDay } from 'date-fns'

export const mapStringToArrayOrUndefined = value =>
  value.length
    ? value
        .trim()
        .split(/[\s:,;\n]+/)
        .map(s => s.trim())
    : undefined

export const valuesAreAllNumbers = input => {
  const values = mapStringToArrayOrUndefined(input)

  if (values) {
    for (let value of values) {
      if (isNaN(value)) {
        return false
      }
    }
  }
  return true
}

export const valuesAreAllEmails = input => {
  if (input) {
    let tempArray = Array.isArray(input) ? input : mapStringToArrayOrUndefined(input)
    let returnValue = false
    for (let value of tempArray) {
      returnValue = !!value
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
    return returnValue
  }
  return false
}

export const checkRelativeDate = date => {
  return date?.match(/^(?<sign>[-+])(?<value>\d+)(?<date_part>[mwd])$/)
}

export const calculateDate = (relativeDate, isFrom) => {
  const match = checkRelativeDate(relativeDate)
  let calculatedDate = new Date()

  if (match) {
    let { sign, value, date_part } = match.groups

    if (sign === '-') {
      value = value * -1
    }

    if (date_part === 'd') {
      calculatedDate = add(calculatedDate, { days: value })
    }
    if (date_part === 'w') {
      calculatedDate = add(calculatedDate, { weeks: value })
    }
    if (date_part === 'm') {
      calculatedDate = add(calculatedDate, { months: value })
    }

    if (isFrom) {
      if (date_part === 'w') {
        // go to first day of week
        calculatedDate = setDay(calculatedDate, 0, { weekStartsOn: 1 })
      }
      if (date_part === 'm') {
        // go to first day of month
        calculatedDate = setDate(calculatedDate, 1)
      }
      // always set the time to midnight
      calculatedDate = set(calculatedDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    } else {
      if (date_part === 'w') {
        // go to last day of week
        calculatedDate = lastDayOfWeek(calculatedDate, { weekStartsOn: 1 })
      }
      if (date_part === 'm') {
        // go to last day of month
        calculatedDate = lastDayOfMonth(calculatedDate)
      }
      // always set the time to 1ms before midnight
      calculatedDate = set(calculatedDate, {
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999,
      })
    }
  }

  return calculatedDate
}

export const getTimeInUTC = () => {
  const currentDate = new Date()
  return `${currentDate.getUTCHours()}:${String(currentDate.getUTCMinutes()).padStart(2, '0')}`
}
