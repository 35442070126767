import Config from '../config/Config'
import axios from 'axios'
import { setupAxiosOktaInterceptors } from '../config/AxiosInterceptors'
import { RebookingDeliveryViewFilter } from './ShipmentService'
import { checkRelativeDate } from '../common/utils/datautils/utils'
import { addDays, addMilliseconds } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export const jobClient = setupAxiosOktaInterceptors(
  axios.create({
    baseURL: `${Config.BACKEND_URL}/${Config.BASEPATH}`,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
)

export interface JobQuery {
  filter?: RebookingDeliveryViewFilter
}

export interface Job {
  jobName?: string
  query?: JobQuery
  frequency?: string //this should be a cron expression
  reportVersion?: string
  mailTo?: string[]
  subjectLine?: string
}

export const fetchJobs = async (
  authState: any
): Promise<{
  data
  totalResources
}> => {
  return jobClient.get(`/jobs/v1/${authState?.accessToken?.claims?.sub}`).then(response => {
    return {
      data: response.data,
      totalResources: response.data.length,
    }
  })
}

export const putJob = async (authState: any, jobName: string, body: Job): Promise<Job> => {
  if (body.query) {
    body.query.filter = convertToFilter(body.query)
  }

  return jobClient
    .put(`/jobs/v1/${authState?.accessToken?.claims?.sub}/job-name/${encodeURI(jobName)}`, body)
    .then(response => response.data ?? [])
}

export const deleteJob = async (authState: any, jobName: string): Promise<Job> => {
  return jobClient
    .delete(`/jobs/v1/${authState?.accessToken?.claims?.sub}/job-name/${encodeURI(jobName)}`)
    .then(response => response.data ?? [])
}

export const logUsage = async (page: string, action: string, params = {}): Promise<string> => {
  return jobClient
    .post('/usage-log', { page, action, application: 'shipment_report', ...params })
    .then(response => response.data)
}

function convertToFilter(query: JobQuery) {
  const iDPPlannedFromTimestamp = query.filter?.iDPPlannedFromTimestamp
    ? checkRelativeDate(query.filter?.iDPPlannedFromTimestamp)
      ? query.filter?.iDPPlannedFromTimestamp
      : zonedTimeToUtc(query.filter?.iDPPlannedFromTimestamp, 'Europe/Brussels').toISOString()
    : undefined

  const iDPPlannedToTimestamp = query.filter?.iDPPlannedToTimestamp
    ? checkRelativeDate(query.filter?.iDPPlannedToTimestamp)
      ? query.filter?.iDPPlannedToTimestamp
      : addMilliseconds(
          addDays(zonedTimeToUtc(query.filter?.iDPPlannedToTimestamp, 'Europe/Brussels'), 1),
          -1
        ).toISOString()
    : undefined

  let rebookingType: any = []
  if (query?.filter?.rebookedCheckbox) {
    rebookingType.push('REBOOKING')
  }
  if (query?.filter?.holdCheckbox) {
    rebookingType.push('HOLD')
  }

    let idpIndicator: any = []
    if (query?.filter?.idpCheckbox) {
      idpIndicator.push(true)
    }
    if (query?.filter?.nonIdpCheckbox) {
      idpIndicator.push(false)
    }

    return {
      ...query?.filter,
      iDPPlannedFromTimestamp: iDPPlannedFromTimestamp,
      iDPPlannedToTimestamp: iDPPlannedToTimestamp,
      // convert some fields
      shipToCountries: query?.filter?.countries,
      rebookingType:
        rebookingType.length != null && rebookingType.length > 0 ? rebookingType : undefined,
      packlistStatuses: ['SCHEDULED', 'READY_FOR_SHIPMENT', 'SHIPPED'],
      // remove fields that are not in API
      rebookedCheckbox: undefined,
      holdCheckbox: undefined,
      idpIndicator:
        idpIndicator.length != null
          ? idpIndicator.length > 1
            ? undefined
            : idpIndicator[0]
          : undefined,
      countries: undefined,
      carrierHubs:
        query?.filter?.carrierHubs?.length != null && query?.filter?.carrierHubs?.length > 0
          ? query?.filter?.carrierHubs
          : undefined,
    }
}
