import './TooltipRebookingContent.css'
import { date } from '../../../common/utils/datautils/formatter'

export const TooltipRebookingContent = ({ type, data }) => {
  const {
    responsiblePartyCode,
    reasonCode,
    iDPPlannedTimestamp,
    plannedDeliveryFromTime,
    plannedDeliveryToTime,
    shipTo,
    deliveryInstructionText,
    alignedWithCarrierIndicator,
    expectedMonthOfDelivery,
  } = data

  const convertToTitle = (type: string) => (type === 'REBOOKING' ? 'Rebooking' : 'On Hold')

  const { addressLine1, addressLine2, addressLine3, city, code, zipCode } = shipTo
  return (
    <>
      <table className="tooltip-rebooking-content">
        <thead>
          <th colSpan={2}>
            <td>{convertToTitle(type)} Details</td>
          </th>
        </thead>
        <tbody>
          {responsiblePartyCode && (
            <tr>
              <td>Responsible Party</td>
              <td>{responsiblePartyCode}</td>
            </tr>
          )}
          {reasonCode && (
            <tr>
              <td>Reason</td>
              <td>{reasonCode}</td>
            </tr>
          )}
          {iDPPlannedTimestamp && (
            <tr>
              <td>New IDP Date</td>
              <td>{date(iDPPlannedTimestamp, false, true)}</td>
            </tr>
          )}
          {plannedDeliveryFromTime && plannedDeliveryToTime && (
            <tr>
              <td>New IDP Time Window</td>
              <td>
                {plannedDeliveryFromTime} - {plannedDeliveryToTime}
              </td>
            </tr>
          )}
          {code && (
            <tr>
              <td>New Ship To Code</td>
              <td>{code}</td>
            </tr>
          )}
          {addressLine1 && (
            <tr>
              <td>New Address</td>
              <td>
                {addressLine1} {addressLine2} {addressLine3}
              </td>
            </tr>
          )}
          {zipCode && (
            <tr>
              <td>New Zip Code</td>
              <td>{zipCode}</td>
            </tr>
          )}
          {city && (
            <tr>
              <td>New City</td>
              <td>{city}</td>
            </tr>
          )}
          {deliveryInstructionText && (
            <tr>
              <td>New Traffic Instructions</td>
              <td>{deliveryInstructionText}</td>
            </tr>
          )}
          {expectedMonthOfDelivery && (
            <tr>
              <td>Expected Month of Delivery</td>
              <td>{expectedMonthOfDelivery}</td>
            </tr>
          )}
          <tr>
            <td>Aligned with Carrier</td>
            <td>{alignedWithCarrierIndicator ? 'Yes' : 'No'}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default TooltipRebookingContent
