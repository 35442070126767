import { date } from '../../common/utils/datautils/formatter'
import { Icon, Tooltip } from '@nike/eds'
import TooltipRebookingContent from './tooltiprebookingcontent/TooltipRebookingContent'
import TooltipTrailerInformation from './tooltiptrailerinformation/TooltipTrailerInformation'
import React from 'react'

export const shipmentReportColumns = [
  {
    Header: () => <div>Packlist</div>,
    accessor: 'shipmentReference',
    Cell: item => (
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: '100px',
        }}
        title={item.value}
      >
        {item.value}
      </div>
    ),
  },
  {
    Header: () => <div>Ship To Number</div>,
    accessor: 'shipToCode',
    Cell: item => <div>{item.value}</div>,
  },
  {
    Header: () => <div>Ship To Name</div>,
    accessor: 'shipToName',
    Cell: item => <div>{item.value}</div>,
  },
  {
    Header: () => <div>Ship To Country</div>,
    accessor: 'shipToCountry',
    Cell: item => <div>{item.value}</div>,
  },
  {
    Header: () => <div>Carrier</div>,
    accessor: 'carrierAccountCode',
    Cell: item => <div>{item.value}</div>,
  },
  {
    Header: () => <div>Hub</div>,
    accessor: 'hubCode',
    Cell: item => <div>{item.value}</div>,
  },
  {
    Header: () => <div># Cartons</div>,
    accessor: 'totalNumberOfCartons',
    Cell: item => <div>{item.value}</div>,
  },
  {
    Header: () => <div>Planned Goods Issue Date</div>,
    accessor: 'plannedGoodsIssueDate',
    Cell: item => <div>{date(item.value)}</div>,
  },
  {
    Header: () => <div>Actual Departure Date</div>,
    accessor: 'actualShipDateTime',
    Cell: item => <div>{date(item.value)}</div>,
  },
  {
    Header: () => <div>Expected Delivery Date</div>,
    accessor: 'iDPPlannedTimestamp',
    Cell: item => (
      <div>
        {date(item.value?.date)}
        <br></br>
        {item.value?.plannedDeliveryFromTime ? ' ' : undefined}
        {item.value?.plannedDeliveryFromTime
          ? item.value?.plannedDeliveryFromTime + ' - ' + item.value?.plannedDeliveryToTime
          : undefined}
      </div>
    ),
  },
  {
    Header: () => <div>IDP Shipment</div>,
    accessor: 'iDPIndicator',
    Cell: item => <div>{item.value === true ? 'Y' : 'N'}</div>,
  },
  {
    Header: () => <div>Traffic Instructions</div>,
    accessor: 'deliveryInstructionText',
    Cell: item => <div>{item.value}</div>,
  },
  {
    Header: () => <div>Type</div>,
    accessor: 'rebookingTypeData',
    disableSortBy: true,
    Cell: item => (
      <div>
        {item.value.type && (
          <Tooltip
            bodySlot={<TooltipRebookingContent type={item.value.type} data={item.value.data} />}
            isDark={true}
            placement={'right'}
          >
            <div
              className="flex-row flex-nowrap content-center justify-items-center"
              style={{ cursor: 'pointer' }}
            >
              <div style={{ fontWeight: 'normal', marginRight: 'var(--eds-space-4)' }}>
                {item.value.type}
              </div>
              <Icon name="InfoCircle" size="s" />
            </div>
          </Tooltip>
        )}
      </div>
    ),
  },
  {
    Header: () => <div>New IDP Date</div>,
    accessor: 'newIDPPlannedTimestamp',
    Cell: item => (
      <div>
        {date(item.value?.date)}
        <br></br>
        {item.value?.plannedDeliveryFromTime ? ' ' : undefined}
        {item.value?.plannedDeliveryFromTime
          ? item.value?.plannedDeliveryFromTime + ' - ' + item.value?.plannedDeliveryToTime
          : undefined}
      </div>
    ),
  },
  {
    Header: () => <div>Last Changed Date</div>,
    accessor: 'lastChangedDateTime',
    Cell: item => <div>{date(item.value, true)}</div>,
  },
  {
    Header: () => <div>Trailer Access Number</div>,
    accessor: 'trailers',
    Cell: item => (
      <div>
        {item.value?.map((it, i) => (
          <Tooltip
            bodySlot={<TooltipTrailerInformation data={it} />}
            isDark={true}
            placement={'right'}
            key={`${it.trailerAccessNumber}-${i}`}
          >
            <div
              className="flex-row flex-nowrap content-center justify-items-center"
              style={{ cursor: 'pointer' }}
            >
              <div style={{ fontWeight: 'normal', marginRight: 'var(--eds-space-4)' }}>
                {it.trailerAccessNumber}
              </div>
              <Icon name="InfoCircle" size="s" />
            </div>
          </Tooltip>
        )) ?? null}
      </div>
    ),
  },
]
